import React from "react";
import Input from "../../CommonComponent/Input";
import Button from "../../CommonComponent/Button";
import { uploadIcon } from "../../../../icons";
import Select from "react-select";

const VersionForm = ({
  values,
  handleInputChange,
  errors,
  handleVersionSubmit,
  isEdit,
  selectedOptions,
  handleChange,
  typeOption,
  isChecked,
  handleCheckboxChange,
}) => {
  return (
    <>
      <div className="create_from_row">
        <Input
          className="project_edit_feature_input"
          errorClassName="err_text"
          type="text"
          placeholder="Enter Version Number"
          label="Version"
          id="version"
          name="version"
          value={values.version}
          onChange={handleInputChange}
          error={errors.version || ""}
        />
        <Input
          className="project_edit_feature_input"
          errorClassName="err_text"
          type="text"
          placeholder="Enter Hardware batch"
          label="Hardware batch"
          id="hardware_batch"
          name="hardware_batch"
          value={values.hardware_batch}
          onChange={handleInputChange}
          error={errors.hardware_batch || ""}
        />

        <Input
          className="project_edit_feature_input"
          errorClassName="err_text"
          type="text"
          placeholder="Enter Series"
          label="Series"
          id="series"
          name="series"
          value={values.series}
          onChange={handleInputChange}
          error={errors.series || ""}
        />
        <Input
          className="project_edit_feature_input"
          errorClassName="err_text"
          type="text"
          placeholder="Enter Description"
          label="Description"
          id="description"
          name="description"
          value={values.description}
          onChange={handleInputChange}
          error={errors.description || ""}
        />
        <div>
          <label className="project_text_area_label img_label">
            Upload bin
          </label>
          <div className="file-input">
            <input
              type="file"
              accept=".bin"
              className="file-input__input"
              onChange={handleInputChange}
              id={`bin_file`}
              name="bin_file"
            />
            <label className="file-input__label" htmlFor="bin_file">
              {uploadIcon}
              <span>Upload file</span>
              {values.bin_file && <span>{values.bin_file.name}</span>}
            </label>
          </div>
          {errors?.bin_file && (
            <span className="err_text">{errors?.bin_file}</span>
          )}
        </div>
        <div>
          <label className="project_text_area_label img_label">
            Upload app configuration
          </label>
          <div className="file-input">
            <input
              type="file"
              accept=".xml"
              onChange={handleInputChange}
              className="file-input__input"
              id={`app_configuration`}
              name="app_configuration"
            />
            <label className="file-input__label" htmlFor="app_configuration">
              {uploadIcon}
              <span>Upload file</span>
              {values.app_configuration && (
                <span>{values.app_configuration.name}</span>
              )}
            </label>
          </div>
          {errors?.app_configuration && (
            <span className="err_text">{errors?.app_configuration}</span>
          )}
        </div>
        <div>
          <label className="project_text_area_label img_label">
            Upload motor configuration
          </label>
          {/* <div className="project_edit_feature_input_file version_feature_input ">
            <Input
              className="create_from_input_content"
              labelClassName="create_from_label"
              errorClassName="err_text"
              type="file"
              id={`motor_configuration`}
              name="motor_configuration"
              accept=".xml"
              onChange={handleInputChange}
            />
          </div> */}
          <div className="file-input">
            <input
              type="file"
              accept=".xml"
              onChange={handleInputChange}
              className="file-input__input"
              id={`motor_configuration`}
              name="motor_configuration"
            />
            <label className="file-input__label" htmlFor="motor_configuration">
              {uploadIcon}
              <span>Upload file</span>
              {values.motor_configuration && (
                <span>{values.motor_configuration.name}</span>
              )}
            </label>
          </div>
          {errors?.motor_configuration && (
            <span className="err_text">{errors?.motor_configuration}</span>
          )}
        </div>

        <div className="project_edit_feature_input">
          <label>Select Existing Batches</label>
          <Select
            defaultValue={selectedOptions}
            isMulti
            name="type"
            options={typeOption}
            onChange={handleChange}
            className="basic-multi-select"
            classNamePrefix="select"
          />
          {/* {statusList.map((status, i) => {
            return (
              <div className="project_common_form_check_box" key={i}>
                <input
                  type="radio"
                  id={status.id}
                  name="status"
                  value={status.value}
                  checked={reqStatus === status.value}
                  onChange={onStatusChange}
                />
                <label htmlhtmlFor={status.id}>{status.display_name}</label>
              </div>
            );
          })} */}
        </div>
        <div className="project_common_form_check_box force_input">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
            label="Force"
          />
          Force
        </div>
      </div>
      <Button
        className="project_submit_bottom_btn center_back_btn"
        buttonClassName="comman_btn"
        onClick={handleVersionSubmit}
        text={isEdit ? "Save" : "Add"}
      />
    </>
  );
};

export default VersionForm;
