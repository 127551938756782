export const routes = {
  homepage: "/",
  all: "*",
  login: "/login",

  dashboard: "/dashboard",

  vehicleInv: "/vehicle-inventory",
  vehicleInvByPage: "/vehicle-inventory/page/:page_number",
  viewVehicleInvPage: "/vehicle-inventory/:id",
  AddVehicleInvPage: "/vehicle-inventory/add",
  updateVehicleInvPage: "/vehicle-inventory/update/:id",

  vehicleInvTrip: "/vehicle-inventory/:id/trips/page/:page_number",

  vehInvErrorLogPage: "/vehicle-inventory/:id/error-logs",
  vehInvActionLogPage: "/vehicle-inventory/:id/action-logs",

  vehiclesPage: "/dtv-vehicles",
  vehiclesByPage: "/dtv-vehicles/page/:page_number",
  createVehTypePage: "/dtv-vehicles/add",
  updateVehTypePage: "/dtv-vehicles/update/:id",

  usersPage: "/dtv-users",
  usersByPage: "/dtv-users/page/:page_number",

  supportPage: "/dtv-support-req",
  supportByPage: "/dtv-support-req/page/:page_number",

  version: "/dtv-version",
  versionByPage: "/dtv-version/page/:page_number",
  addVersion: "/dtv-version/add",
};
