import React, { useState } from "react";
import VehTypeForm from "../../component/Common/PageComponent/Vehicles/VehTypeForm";
import CommonHeading from "../../component/Common/CommonComponent/CommonHeading";
import { vehTypeFormValidate } from "../../component/Common/PageComponent/Vehicles/VehiclesCommon";
import { backBtnArrow } from "../../icons";
import { useNavigate } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import {
  addShredderModel,
  updateShredderModel,
} from "../../store/slice/shredderSlice";
import { useDispatch, useSelector } from "react-redux";
import { fileUpload } from "../../helpers/fileUpload";
import Loader from "../../component/Common/CommonComponent/Loader";

const CreateVehTypePage = ({ editDetail, isEdit }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [uploading, setUploading] = useState(false);
  const isLoading = useSelector((state) => state.shredder.loader);

  const initialFValues = {
    name: editDetail && editDetail.name ? editDetail.name : "",
    description:
      editDetail && editDetail.description ? editDetail.description : "",
    image: editDetail && editDetail.image ? editDetail.image : "",
    image_preview:
      editDetail && editDetail.image
        ? `${process.env.REACT_APP_FILE_BASE_URL}/${editDetail.image}`
        : "",
  };

  //   const [variants, setVariants] = useState([
  //     {
  //       var_name: "",
  //       item: [{ item_name: "" }],
  //     },
  //   ]);
  //   const [variantErr, setVariantErr] = useState("");

  // validation
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    return vehTypeFormValidate(fieldValues, temp, setValues, values, setErrors);
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const addMewVehicle = async () => {
    if (validate()) {
      setUploading(true);
      const formData = new FormData();
      let shredderImgUrl;
      if (values.image && typeof values.image !== "string") {
        formData.append("file", values.image);
        shredderImgUrl = await dispatch(fileUpload(formData));
        formData.delete("file");
      } else {
        shredderImgUrl = values.image;
      }
      setUploading(false);
      const data = {
        name: values.name,
        image:
          typeof values.image !== "string"
            ? shredderImgUrl.payload
            : shredderImgUrl,
        description: values.description,
      };
      if (isEdit) {
        dispatch(
          updateShredderModel({
            data,
            id: editDetail.id,
            toast: "Shredder Model updated!",
            navigate,
          })
        );
      } else {
        dispatch(
          addShredderModel({
            data,
            toast: "New Shredder Model added!",
            navigate,
          })
        );
      }
    }
  };

  return (
    <div className="project_edit_main_content">
      <CommonHeading heading={isEdit ? "EDIT MODEL" : "ADD MODEL"} />
      {isLoading || uploading ? (
        <Loader />
      ) : (
        <>
          <div className="project_edit_main_link">
            <div className="comman_center_btn_container">
              <button className="go_back_btn" onClick={() => navigate(-1)}>
                {backBtnArrow} Back
              </button>
            </div>
          </div>
          <VehTypeForm
            values={values}
            handleInputChange={handleInputChange}
            errors={errors}
            setValues={setValues}
            setErrors={setErrors}
            validate={validate}
            addMewVehicle={addMewVehicle}
            isEdit={isEdit}
            //   variants={variants}
            //   setVariants={setVariants}
            //   setVariantErr={setVariantErr}
            //   variantErr={variantErr}
          />
        </>
      )}
    </div>
  );
};

export default CreateVehTypePage;
