import { Navigate } from "react-router-dom";
import AuthPage from "./component/Layout/AuthPage";
import Layout from "./component/Layout/MainLayout";
import Dashboard from "./pages/Dashboard/Dashboard";
import LoginPage from "./pages/Login/LoginPage";
import Page404 from "./pages/Page404/Page404";

import { routes } from "../src/constants";
import VehicleInvPage from "./pages/Inventory/VehicleInvPage";
import ViewVehicleInvPage from "./pages/Inventory/ViewVehicleInvPage";
import TripsPage from "./pages/Trips/TripsPage";
import ErrorLogsPage from "./pages/Logs/ErrorLogsPage";
import ActionLogsPage from "./pages/Logs/ActionLogsPage";
import VehiclesPage from "./pages/Vehicles/VehiclesPage";
import CreateVehTypePage from "./pages/Vehicles/CreateVehTypePage";
import AddVehicleInvPage from "./pages/Inventory/AddVehicleInvPage";
import EditVehTypePage from "./pages/Vehicles/EditVehTypePage";
import UpdateVehicleInvPage from "./pages/Inventory/UpdateVehicleInvPage";
import UsersPage from "./pages/Users/UsersPage";
import SupportReqPage from "./pages/Support/SupportReqPage";
import AddVersionPage from "./pages/Version/AddVersionPage";
import VersionPage from "./pages/Version/VersionPage";

const getRoutes = (user) => [
  {
    path: routes.homepage,
    element: !user ? <AuthPage /> : <Navigate to={routes.dashboard} />,
    children: [
      { path: routes.login, element: <LoginPage /> },
      { path: routes.homepage, element: <Navigate to={routes.login} /> },
    ],
  },
  {
    path: routes.homepage,
    element: user ? <Layout /> : <Navigate to={routes.login} />,
    children: [
      { path: routes.dashboard, element: <Dashboard /> },
      { path: routes.vehicleInv, element: <VehicleInvPage /> },
      { path: routes.vehicleInvByPage, element: <VehicleInvPage /> },
      { path: routes.viewVehicleInvPage, element: <ViewVehicleInvPage /> },
      { path: routes.AddVehicleInvPage, element: <AddVehicleInvPage /> },
      { path: routes.updateVehicleInvPage, element: <UpdateVehicleInvPage /> },

      { path: routes.vehicleInvTrip, element: <TripsPage /> },

      { path: routes.vehInvErrorLogPage, element: <ErrorLogsPage /> },

      { path: routes.vehInvActionLogPage, element: <ActionLogsPage /> },

      { path: routes.vehiclesPage, element: <VehiclesPage /> },
      { path: routes.vehiclesByPage, element: <VehiclesPage /> },
      { path: routes.createVehTypePage, element: <CreateVehTypePage /> },
      { path: routes.updateVehTypePage, element: <EditVehTypePage /> },

      { path: routes.usersPage, element: <UsersPage /> },
      { path: routes.usersByPage, element: <UsersPage /> },

      { path: routes.supportPage, element: <SupportReqPage /> },
      { path: routes.supportByPage, element: <SupportReqPage /> },

      { path: routes.version, element: <VersionPage /> },
      { path: routes.versionByPage, element: <VersionPage /> },
      { path: routes.addVersion, element: <AddVersionPage /> },

      { path: routes.homepage, element: <Navigate to={routes.dashboard} /> },
    ],
  },
  {
    path: "",
    element: !user ? <Navigate to={routes.login} /> : <Page404 />,
    children: [
      { path: routes.login, element: <LoginPage /> },
      { path: "*", element: <LoginPage /> },
    ],
  },
];

export default getRoutes;
