import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { checkIcon, crossIcon, editIcon, eyeIcon } from "../../../../icons";
import { routes } from "../../../../constants";
import { tableHeadItemsForVehicles } from "../../CommonComponent/TableHeads";

const VehicleInvList = ({ list }) => {
  return (
    <>
      {list?.length === 0 || !list ? (
        <p className="no_data_found">No data Found</p>
      ) : (
        <table className="custom_data_table">
          <thead className="custom_data_table_head">
            <tr>
              {tableHeadItemsForVehicles.map((item, index) => {
                return (
                  <th
                    className="custom_data_table_heading text-center"
                    key={item.id}
                  >
                    {item.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="custom_data_table_body">
            {list?.map((item, i) => {
              return (
                <tr className="custom_data_table_row" key={item.id}>
                  <td className="custom_data_table_item table_item">
                    {item.shredder_name}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.shredder_model.name}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {" "}
                    {item.vin_number}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.shredder_uuid}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.status ? checkIcon : crossIcon}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {/* {moment
                      .utc(item.manufacturing_date, "YYYYMMDD HH:mm:ss")
                      .clone()
                      .tz(defaultTimeZone)
                      .format("Do MMMM YYYY")} */}
                    {item.manufacturing_date &&
                      moment(item.manufacturing_date).format("DD/MM/YYYY")}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {/* {item.purchased_date
                      ? moment
                          .utc(item.purchased_date, "YYYYMMDD HH:mm:ss")
                          .clone()
                          .tz(defaultTimeZone)
                          .format("Do MMMM YYYY")
                      : "-"} */}
                    {item.purchased_date
                      ? moment(item.purchased_date).format("DD/MM/YYYY")
                      : "-"}
                  </td>
                  <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                    <div className="custom_data_table_view_edit_btn_item_row">
                      <Link
                        className="custom_data_table_view_edit_item_btn"
                        aria-label="view"
                        to={`${routes.vehicleInv}/${item.id}`}
                      >
                        {eyeIcon}
                      </Link>
                      <Link
                        aria-label="edit"
                        // to={`/update-coupon/${coupon.id}`}
                        // state={{ coupon: coupon }}
                        to={`${routes.vehicleInv}/update/${item.id}`}
                        className="custom_data_table_view_edit_item_btn"
                      >
                        {editIcon}
                      </Link>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}{" "}
    </>
  );
};

export default VehicleInvList;
