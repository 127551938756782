import React from "react";
import Input from "../../CommonComponent/Input";
import Button from "../../CommonComponent/Button";
import { Form } from "react-bootstrap";
import { addIcon, editIcon } from "../../../../icons";
import { imagePreviewProps } from "../../../../helpers/commonFunction";

const VehicleTypeForm = ({
  values,
  setValues,
  errors,
  setErrors,
  handleInputChange,
  addMewVehicle,
  isEdit,
  //   variants,
  //   setVariants,
  //   setVariantErr,
}) => {
  //////////// MULTIPLE IMAGES ////////////////////
  //   const [images, setImages] = useState([]);
  //   const [imagesPrev, setImagesPrev] = useState([]);
  //   const [imgErr, setImgErr] = useState("");

  //   const selectImagesHandler = (e) => {
  //     const { files } = e.target;
  //     let imgErr = "";

  //     if (files.length > 0) {
  //       let teImage = [];
  //       for (let key in files) {
  //         if (key !== "length" && key !== "item") {
  //           if (!files[key].name.match(/.(jpg|jpeg|png)$/i)) {
  //             imgErr = "Please add valid image (png/jpeg/jpg)";
  //             continue;
  //           }
  //           if (files[key].size > 50000000) {
  //             imgErr = "Image size should not be greater than 50MB";
  //             continue;
  //           }
  //           teImage.push(files[key]);
  //         }
  //       }

  //       const tempPrev = [...imagesPrev];
  //       for (let i = 0; i < teImage.length; i++) {
  //         tempPrev.push(URL.createObjectURL(teImage[i]));
  //       }

  //       setImages([...images, ...teImage]);
  //       setImagesPrev([...tempPrev]);
  //       setImgErr(imgErr);
  //     }
  //   };

  //   const removeImgHandle = (i) => {
  //     const imgArr = [...images];
  //     const imgArrPreview = [...imagesPrev];
  //     imgArr.splice(i, 1);
  //     imgArrPreview.splice(i, 1);

  //     setImages(imgArr);
  //     setImagesPrev(imgArrPreview);
  //   };
  //////////// MULTIPLE IMAGES ////////////////////

  ///////////// Variants /////////////////////////
  //   console.log("dfsdfasdf", values);
  //   console.log("variants", variants);

  //   const handleAddVariant = () => {
  //     setVariants([
  //       ...variants,
  //       {
  //         var_name: "",
  //         item: [{ item_name: "" }],
  //       },
  //     ]);
  //     setVariantErr("");
  //   };

  //   const handleRemoveVariant = (index) => {
  //     const list = [...variants];
  //     list.splice(index, 1);
  //     setVariants(list);
  //     setVariantErr("");
  //   };

  //   const handleAddItem = (varIndex) => {
  //     const newItem = { item_name: "" };
  //     const list = [...variants];
  //     const item = [...list[varIndex].item, newItem];
  //     list[varIndex] = { ...list[varIndex], item };
  //     setVariants(list);
  //     setVariantErr("");
  //   };

  //   const handleRemoveItem = (varIndex, itemIndex) => {
  //     const newList = variants.map((variant, index) => {
  //       if (index === varIndex) {
  //         return {
  //           ...variant,
  //           item: [
  //             ...variant.item.slice(0, itemIndex),
  //             ...variant.item.slice(itemIndex + 1),
  //           ],
  //         };
  //       }
  //       return variant;
  //     });
  //     setVariants(newList);
  //     setVariantErr("");
  //   };
  ///////////// Variants //////////////////////////

  const imageRemoveHandler = () => {
    setValues({ ...values, image: "", image_preview: "" });
  };

  return (
    <>
      <div className="create_from_row">
        {/* <div className="login_input_row mb-0 multiple_image_select_input_container span_2">
          <div className="menu_img_title_btn_container">
            <label className="create_from_label multiple_img_label">
              Vehicle Images
            </label>
            <label
              className="comman_btn veh-image-upload-btn"
              htmlFor="veh-image-input"
            >
              Add Images
            </label>
            <Input
              id="veh-image-input"
              type="file"
              multiple={true}
              accept="image/*"
              name="images"
              value=""
              onChange={selectImagesHandler}
              className="veh-image-upload-modal-input"
            />
          </div>
          {imagesPrev?.length !== 0 && (
            <div className="menu_img_selected_container">
              <div className="menu_img_selected_images_content view_img_container">
                {imagesPrev?.map((item, i) => {
                  return (
                    <div className="menu_img_selected_image_preview" key={i}>
                      <img
                        className="menu_img_selected_single_image"
                        src={item}
                        alt="veh_image"
                      />
                      <button
                        className="selected_single_image_remove_btn"
                        onClick={() => removeImgHandle(i)}
                      >
                        {closeIcon}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {imgErr && <span className="err_text">{imgErr}</span>}
        </div> */}

        <div>
          <label className="project_text_area_label img_label">
            Add Shredder Image
          </label>
          <div className="project_edit_feature_input_file">
            <Input
              className="create_from_input_content"
              labelClassName="create_from_label"
              errorClassName="err_text"
              type="file"
              id={`image`}
              labelOtherProps={
                <span>{!values.image ? addIcon : editIcon}</span>
              }
              name="image"
              accept="image/*"
              onChange={handleInputChange}
              onClick={(e) => (e.target.value = null)}
              imageProps={imagePreviewProps(
                values.bin_file,
                values.bin_file_preview,
                imageRemoveHandler
              )}
            />
          </div>
          {errors?.image && <span className="err_text">{errors?.image}</span>}
        </div>

        <div className="project_edit_feature_input mt-0">
          <Input
            className="project_edit_feature_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Shredder Name"
            label="Shredder Name"
            id="name"
            name="name"
            value={values.name}
            onChange={handleInputChange}
            error={errors?.name || ""}
          />
          <div className="project_edit_feature_input">
            <h4 className="project_text_area_label">Shredder Description</h4>
            <Form.Control
              className="project_text_area"
              type="text"
              placeholder="Enter Shredder Description"
              as="textarea"
              rows={4}
              id="description"
              name="description"
              value={values.description}
              onChange={handleInputChange}
            />
            <span className="err_text">{errors?.description || ""}</span>
          </div>
        </div>

        {/* <div className="project_edit_feature_input">
          <h4 className="project_text_area_label">Shredder Variants</h4>
          {variants.map((variant, i) => (
            <div key={i}>
              <Input
                className="project_edit_feature_input"
                type="text"
                placeholder="Enter Variant Name"
                label="Variant Name"
                id="variant"
                name="variant"
                value={variant.var_name}
                onChange={handleInputChange}
              />
              {variant.item?.map((item, j) => (
                <>
                  <Input
                    className="project_edit_feature_input"
                    type="text"
                    placeholder="Enter Variant Type"
                    label="Variant type"
                    id="variant"
                    name="variant"
                    value={item.item_name}
                    onChange={handleInputChange}
                  />
                  <div className="add_on_input_content_btn_row span_2">
                    {variant.item.length !== 1 && (
                      <button
                        className="add_on_input_content_remove_btn"
                        onClick={() => handleRemoveItem(i, j)}
                      >
                        {removeIcon} Remove
                      </button>
                    )}
                  </div>
                  <div className="add_on_input_content_btn_row span_2">
                    {variant.item.length - 1 === j && (
                      <button
                        className="add_on_input_content_add_btn"
                        onClick={() => handleAddItem(i)}
                      >
                        {addIcon}
                      </button>
                    )}
                  </div>
                </>
              ))}
              <div className="add_on_input_content_btn_row">
                {variants.length - 1 === i && (
                  <button
                    className="add_on_input_content_add_btn"
                    onClick={() => handleAddVariant()}
                  >
                    Add
                  </button>
                )}
                {variants.length !== 1 && (
                  <button
                    className="add_on_input_content_remove_btn"
                    onClick={() => handleRemoveVariant(i)}
                  >
                    Remove
                  </button>
                )}
                {variants.length === 1 && menuDetail && (
                <button
                  className="add_on_input_content_remove_btn"
                  // onClick={(e) => handleRemoveFirstVariation(i)}
                //   onClick={(e) => clearFirstVariation(i)}
                >
                  Clear
                </button>
              )}
              </div>
            </div>
          ))}

          <span className="err_text">{errors?.desc || ""}</span>
        </div> */}
      </div>
      <Button
        className="project_submit_bottom_btn center_back_btn"
        buttonClassName="comman_btn"
        onClick={addMewVehicle}
        text={isEdit ? "Save" : "Add"}
      />
    </>
  );
};

export default VehicleTypeForm;
