import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { toast } from "react-toastify";

// File upload action
export const fileUpload = createAsyncThunk(
  "fileUpload",
  async (formData, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/file/upload`,
        formData,
        authHeaders()
      );
      return response.data.key;
    } catch (error) {
      toast.error("File upload failed.", { autoClose: 3000 });
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

// Version list
export const getVersion = createAsyncThunk("getVersion", async (data) => {
  try {
    const response = await Axios.get(
      `/version-list?start=${data.start || 0}&limits=${data.limits || 10}`,
      authHeaders()
    );
    return response.data.data;
  } catch (error) {
    toast.error(error.response.data.message, {
      autoClose: 3000,
    });
    return error.response.data.message;
  }
});

// Batch list
export const getBatchList = createAsyncThunk("getBatchList", async (data) => {
  try {
    const response = await Axios.get(`/batch-list`, authHeaders());
    return response.data.data;
  } catch (error) {
    toast.error(error.response.data.message, {
      autoClose: 3000,
    });
    return error.response.data.message;
  }
});

// Add version
export const addVersion = createAsyncThunk(
  "addVersion",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(`/version`, data.data, authHeaders());
      data.onSuccess(response);
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      data.onFailure(error);
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

// delete version list
export const deleteVersionModal = createAsyncThunk(
  "deleteVersionModal",
  async (data) => {
    try {
      const response = await Axios.delete(
        `/delete-version/${data.id}`,
        authHeaders()
      );
      if (response.status === 201 && data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      console.log("error:", error.response);
    }
  }
);

const initialState = {
  versionList: [],
  totalVersionCount: 0,
  batchList: [],
  versionDetail: {},
  loader: false,
  error: null,
};

const versionSlice = createSlice({
  name: "version",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // File Upload
    builder.addCase(fileUpload.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(fileUpload.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(fileUpload.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Version List
    builder.addCase(getVersion.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getVersion.fulfilled, (state, action) => {
      state.loader = false;
      state.versionList = action.payload.rows;
      state.totalVersionCount = action.payload.count;
    });
    builder.addCase(getVersion.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Batch List
    builder.addCase(getBatchList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getBatchList.fulfilled, (state, action) => {
      state.loader = false;
      state.batchList = action.payload;
    });
    builder.addCase(getBatchList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Add Version
    builder.addCase(addVersion.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(addVersion.fulfilled, (state, action) => {
      state.loader = false;
      state.versionDetail = action.payload;
    });
    builder.addCase(addVersion.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    // Delete Version
    builder.addCase(deleteVersionModal.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(deleteVersionModal.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(deleteVersionModal.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default versionSlice.reducer;
