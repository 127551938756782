import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeIcon } from "../../../icons";
import { getShredderModelById } from "../../../store/slice/shredderSlice";
import Loader from "../CommonComponent/Loader";

const ViewVehicleModal = ({ closeModal, vehModalId, prevVehModalId }) => {
  const dispatch = useDispatch();

  const shredderDetail = useSelector((state) => state.shredder.shredderDetail);
  const isLoading = useSelector((state) => state.shredder.extraLoader);

  useEffect(() => {
    if (prevVehModalId !== vehModalId) {
      dispatch(getShredderModelById({ id: vehModalId }));
    }
  }, [vehModalId]);

  return (
    <div className="modal_container">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="modal_heading">
            <h1 className="modal_main_heading">Vehicle Details</h1>
            <button className="modal_close_btn" onClick={() => closeModal()}>
              {closeIcon}
            </button>
          </div>
          <div className="modal_detail_container">
            <div className="modal_detail_top_content">
              <div className="modal_detail_top_first_content">
                <img
                  src={`${process.env.REACT_APP_FILE_BASE_URL}/${shredderDetail.image}`}
                />
                {/* <p>Shredder 2.0</p> */}
                <p>{shredderDetail.name}</p>
              </div>
            </div>
            <div className="modal_detail_container_grid">
              {/* <div className="modal_detail_content">
            <h2 className="modal_detail_content_heading">Vehicle Name</h2>
            <p>Shredder 2.0</p>
          </div> */}
              <div className="modal_detail_content span_2">
                <h2 className="modal_detail_content_heading">About Vehicle</h2>
                {/* <p>Shredder limit 40km/h</p> */}
                <p>{shredderDetail.description}</p>
              </div>
            </div>
          </div>
        </>
      )}

      {/* <div className="modal_btn_row">
        <button
          onClick={() => hideModal()}
          className="modal_btn modal_cancel_btn"
        >
          Cancel
        </button>
        <button
          className="modal_btn modal_delete_btn"
          onClick={() => deleteSalonHandler()}
        >
          Continue
        </button>
      </div> */}
    </div>
  );
};

export default ViewVehicleModal;
