import React from "react";
import { Link } from "react-router-dom";
import "../../css/component.css";
import { useLocation } from "react-router-dom";
import Logo from "../../images/LogoImage/Logo.svg";
import { routes } from "../../constants";
import {
  complaintIcon,
  dashboardIcon,
  gearIcon,
  shredderIcon,
  userIcon,
  versionIcon,
} from "../../icons";

const Sidebar = () => {
  const { pathname } = useLocation();
  return (
    <div className="sidebar_container">
      <Link
        to={routes.dashboard}
        className={`sidebar_logo ${
          pathname.match(/^.*dashboard.*$/) && "active"
        } `}
      >
        <div>
          <img src={Logo} alt="dtv_centre-logo" className="full_sidebar_logo" />
        </div>
      </Link>
      <ul className="sidebar_nav">
        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              pathname.match(/^.*dashboard.*$/) && "active"
            } `}
            to={routes.dashboard}
          >
            {dashboardIcon}
            <span className="sidebar_link_title">Dashboard</span>
          </Link>
        </li>
        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              pathname.match(/^.*vehicle-inventory.*$/) && "active"
            } `}
            to={`${routes.vehicleInv}/page/1`}
          >
            {shredderIcon}
            <span className="sidebar_link_title">Vehicle Inventory</span>
          </Link>
        </li>
        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              pathname.match(/^.*dtv-vehicles.*$/) && "active"
            } `}
            to={`${routes.vehiclesPage}/page/1`}
          >
            {gearIcon}
            <span className="sidebar_link_title">Vehicles Types</span>
          </Link>
        </li>
        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              pathname.match(/^.*dtv-users.*$/) && "active"
            } `}
            to={`${routes.usersPage}/page/1`}
          >
            {userIcon}
            <span className="sidebar_link_title">Users</span>
          </Link>
        </li>
        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              pathname.match(/^.*dtv-support-req.*$/) && "active"
            } `}
            to={`${routes.supportPage}/page/1`}
          >
            {complaintIcon}
            <span className="sidebar_link_title">Support Request</span>
          </Link>
        </li>
        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              pathname.match(/^.*dtv-version.*$/) && "active"
            } `}
            to={`${routes.version}/page/1`}
          >
            {versionIcon}
            <span className="sidebar_link_title">Version</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
