import React, { useEffect } from "react";
import CommonHeading from "../../component/Common/CommonComponent/CommonHeading";
import {
  dashboardUser,
  dashboardShredderIcon,
  dashboardGearIcon,
} from "../../icons";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardCount } from "../../store/slice/dashboardSlice";
import Loader from "../../component/Common/CommonComponent/Loader";

const Dashboard = () => {
  const dispatch = useDispatch();

  const dashBoardCount = useSelector((state) => state.dashboard.dashboardCount);
  const isLoading = useSelector((state) => state.dashboard.loader);

  useEffect(() => {
    dispatch(getDashboardCount());
  }, []);

  return (
    <div className="project_edit_main_content">
      <CommonHeading heading={"DASHBOARD"} />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="dashboard_body">
          <div className="dashboard_top_column_row">
            <div className="dashboard_top_column">
              <div className="dashboard_top_column_main_icon">
                {dashboardUser}
              </div>
              <div className="dashboard_top_column_left_content">
                <h2 className="dashboard_top_column_left_count_text">
                  {dashBoardCount?.userCount}
                </h2>
                <p className="dashboard_top_column_left_peragraph_text">
                  Users
                </p>
              </div>
            </div>
            <div className="dashboard_top_column">
              <div className="dashboard_top_column_main_icon">
                {dashboardShredderIcon}
              </div>
              <div className="dashboard_top_column_left_content">
                <h2 className="dashboard_top_column_left_count_text">
                  {" "}
                  {dashBoardCount?.shredderCount}
                </h2>
                <p className="dashboard_top_column_left_peragraph_text">
                  Shredders
                </p>
              </div>
            </div>
            <div className="dashboard_top_column">
              <div className="dashboard_top_column_main_icon">
                {dashboardGearIcon}
              </div>
              <div className="dashboard_top_column_left_content">
                <h2 className="dashboard_top_column_left_count_text">
                  {" "}
                  {dashBoardCount?.shredderModelCount}
                </h2>
                <p className="dashboard_top_column_left_peragraph_text">
                  Models
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
