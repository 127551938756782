import moment from "moment";
import React from "react";
import { defaultTimeZone } from "../../../../helpers/commonFunction";

const VehicleInvDetails = ({ invVehDetail }) => {
  return (
    <div className="create_from_row">
      <div className="project_view_list_item">
        <h2>UUID</h2>
        <p>{invVehDetail.shredder_uuid}</p>
      </div>
      <div className="project_view_list_item">
        <h2>VIN</h2>
        <p>{invVehDetail.vin_number}</p>
      </div>
      <div className="project_view_list_item">
        <h2>Shredder Name</h2>
        <p>{invVehDetail.shredder_name}</p>
      </div>
      <div className="project_view_list_item">
        <h2>Barcode</h2>
        <p>{invVehDetail.barcode ? invVehDetail.barcode : "-"}</p>
      </div>
      <hr className="hr_line_divider span_2" />
      <div className="project_view_list_item span_2">
        <h2>Shredder Model</h2>
        <div className="project_view_list_multi_container">
          <div className="project_view_list_multi_item">
            <img
              src={`${process.env.REACT_APP_FILE_BASE_URL}/${invVehDetail.shredder_model?.image}`}
            />
            <div className="project_view_list_multi_item_container">
              <p className="project_view_list_multi_element">
                <span className="project_view_list_multi_span">
                  Model Name:
                </span>{" "}
                {invVehDetail.shredder_model?.name}
              </p>
              <p className="project_view_list_multi_element">
                <span className="project_view_list_multi_span">
                  Model Desc:{" "}
                </span>
                {invVehDetail.shredder_model?.description}
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr className="span_2" />
      <div className="project_view_list_item">
        <h2>Firmware</h2>
        <p>{invVehDetail.firmware}</p>
      </div>
      <div className="project_view_list_item">
        <h2>Hardware</h2>
        <p>{invVehDetail.batch}</p>
      </div>
      <div className="project_view_list_item">
        <h2>Manufacture Date</h2>
        <p>
          {moment
            .utc(invVehDetail.manufacturing_date, "YYYYMMDD HH:mm:ss")
            .clone()
            .tz(defaultTimeZone)
            .format("Do MMMM YYYY")}
        </p>
      </div>
      <div className="project_view_list_item">
        <h2>Purchased Date</h2>
        <p>
          {invVehDetail.purchased_date
            ? moment
                .utc(invVehDetail.purchased_date, "YYYYMMDD HH:mm:ss")
                .clone()
                .tz(defaultTimeZone)
                .format("Do MMMM YYYY")
            : "-"}
        </p>
      </div>
    </div>
  );
};

export default VehicleInvDetails;
