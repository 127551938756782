import React, { useEffect, useRef } from "react";
import { Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Loader from "../../component/Common/CommonComponent/Loader";
import CommonHeading from "../../component/Common/CommonComponent/CommonHeading";
import RequestList from "../../component/Common/PageComponent/Support/RequestList";
import { reFresh } from "../../icons";
import ViewSupportDetail from "../../component/Common/Modals/ViewSupportDetail";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSupportRequests } from "../../store/slice/supportReqSlice";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "../../component/Pagination/Pagination";
import UpdateSupportReq from "../../component/Common/Modals/UpdateSupportReq";
import { routes } from "../../constants";
import moment from "moment";

const SupportReqPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const ref = useRef(false);
  const { page_number } = params;

  const totalCount = useSelector(
    (state) => state.supportReq.totalsupportReqCount
  );
  const supportReqList = useSelector(
    (state) => state.supportReq.supportReqList
  );
  const isLoading = useSelector((state) => state.supportReq.loader);

  const [pageNumber, setPageNumber] = useState(page_number - 1);

  const [viewReq, setViewReq] = useState(false);
  const [reqId, setReqId] = useState("");
  const [prevReqId, setPrevReq] = useState("");

  const [viewUpdateReq, setViewUpdateReq] = useState(false);
  const [updateReqData, setUpdateReqData] = useState("");

  const [filterStatus, setFilterStatus] = useState("");
  const [filterDate, setFilterDate] = useState("");

  const statusOptions = [
    { value: "", label: "All" },
    { value: "Pending", label: "Pending" },
    { value: "Resolved", label: "Resolved" },
    { value: "Reopen", label: "Reopen" },
    { value: "Closed", label: "Closed" },
  ];

  const handleStatusChange = (e) => {
    setFilterStatus(e.target.value);
  };

  useEffect(() => {
    if (ref.current) {
      if (page_number !== 1) {
        navigate(`${routes.supportPage}/page/1`);
      }
      dispatch(
        getSupportRequests({
          status: filterStatus,
          request_date: !filterDate
            ? ""
            : moment(filterDate).format("YYYY-MM-DD"),
          start: (page_number - 1) * 10,
          limits: 10,
        })
      );
    }
  }, [filterStatus, filterDate]);

  useEffect(() => {
    if (ref.current) {
      dispatch(
        getSupportRequests({
          status: filterStatus,
          request_date: !filterDate
            ? ""
            : moment(filterDate).format("YYYY-MM-DD"),
          start: (page_number - 1) * 10,
          limits: 10,
        })
      );
    }
  }, [pageNumber]);

  useEffect(() => {
    dispatch(
      getSupportRequests({
        status: filterStatus,
        request_date: !filterDate
          ? ""
          : moment(filterDate).format("YYYY-MM-DD"),
        start: (page_number - 1) * 10,
        limits: 10,
      })
    );
    ref.current = true;
  }, []);

  const showDetailModal = (id) => {
    setReqId(id);
    setViewReq(true);
  };

  const closeModal = () => {
    setPrevReq(reqId);
    setViewReq(false);
  };
  const showUpdateReqModal = (req) => {
    setUpdateReqData(req);
    setViewUpdateReq(true);
  };

  const closeUpdateReqModal = () => {
    setUpdateReqData("");
    setViewUpdateReq(false);
  };

  const onPageChange = (page_number) => {
    if (ref.current) {
      setPageNumber(page_number);
    }
  };

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading="SUPPORT REQUEST" />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="creat_edit_project_btn_row_flex">
              <div></div>
              <div className="common_all_filter_row">
                {/* <div className="common_filter_input_box first_filter_container">
                  <div className="common_select_box">
                    <Form.Select
                      onChange={handleServiceIdChange}
                      id="employee_name"
                      name="employee_name"
                      className="select_form"
                      defaultValue={"DEFAULT"}
                    >
                      <option value="DEFAULT" disabled>
                        Filter By Service
                      </option>
                      <option value="">ALL</option>
                      {services?.map((service, i) => {
                        return (
                          <option key={i} value={service.id}>
                            {service.service_name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>
                </div> */}
                <div className="common_filter_input_box first_filter_container">
                  <div className="common_select_box">
                    <Form.Select
                      onChange={(e) => handleStatusChange(e)}
                      id="employee_name"
                      name="employee_name"
                      className="select_form"
                      defaultValue={"DEFAULT"}
                    >
                      <option value="DEFAULT" disabled>
                        Filter By Status
                      </option>
                      {statusOptions.map((status, i) => {
                        return (
                          <option key={i} value={status.value}>
                            {status.label}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>
                </div>
                <div className="common_filter_input_box">
                  <div className="project_date_range_input_container">
                    <div className="custom_date_picker">
                      <DatePicker
                        isClearable={filterDate}
                        onChange={(date) => {
                          setFilterDate(date);
                        }}
                        selected={filterDate}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Search By Date..."
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        maxDate={new Date()}
                      />
                    </div>
                    {/* <span
                      className="filter_input_inside_btn"
                      role="button"
                      // onClick={resetDateHandler}
                    >
                      {reFresh}
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="custom_data_table_content">
              <RequestList
                list={supportReqList}
                showDetailModal={showDetailModal}
                showUpdateReqModal={showUpdateReqModal}
              />
            </div>

            {totalCount > 0 && (
              <Pagination
                totalRecords={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </>
        )}
      </div>
      <Modal
        className="modal_main_component modal_background_content"
        dialogClassName="custom-dialog"
        show={viewReq}
        onHide={closeModal}
        centered
      >
        <ViewSupportDetail
          closeModal={closeModal}
          reqId={reqId}
          prevReqId={prevReqId}
        />
      </Modal>
      <Modal
        className="modal_main_component modal_background_content"
        dialogClassName="small-dialog"
        show={viewUpdateReq}
        onHide={closeUpdateReqModal}
        centered
      >
        <UpdateSupportReq
          closeModal={closeUpdateReqModal}
          updateReqData={updateReqData}
          pageNumber={page_number}
        />
      </Modal>
    </>
  );
};

export default SupportReqPage;
