import React from "react";
import { Link } from "react-router-dom";
import { eyeIcon } from "../../../../icons";
import moment from "moment/moment";
import { defaultTimeZone } from "../../../../helpers/commonFunction";
import { tableHeadItemsForErrorLog } from "../../CommonComponent/TableHeads";

const ErrorLogTableList = ({ list }) => {
  return (
    <>
      {list?.length === 0 || !list ? (
        <p className="no_data_found">No data Found</p>
      ) : (
        <table className="custom_data_table">
          <thead className="custom_data_table_head">
            <tr>
              {tableHeadItemsForErrorLog.map((item, index) => {
                return (
                  <th
                    className="custom_data_table_heading text-center"
                    key={item.id}
                  >
                    {item.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="custom_data_table_body">
            {list?.map((error, i) => {
              return (
                <tr className="custom_data_table_row" key={error.id}>
                  <td className="custom_data_table_item table_item">
                    {error.error_code}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {error.error_msg}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {error.error_type}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {moment
                      .utc(error.error_time, "YYYYMMDD HH:mm:ss")
                      .clone()
                      .tz(defaultTimeZone)
                      .format("DD/MM/YYYY, hh:mm A")}
                  </td>
                  <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                    <div className="custom_data_table_view_edit_btn_item_row">
                      <Link
                        className="custom_data_table_view_edit_item_btn"
                        aria-label="view"
                        //   state={name === "salon" && { salon: salon }}
                      >
                        {eyeIcon}
                      </Link>
                      {/* <Link
                    aria-label="edit"
                    className="custom_data_table_view_edit_item_btn"
                  >
                    {editIcon}
                  </Link> */}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
};

export default ErrorLogTableList;
