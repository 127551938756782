export const userDefaultIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z" />
  </svg>
);

export const addIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
    <line x1="12" y1="8" x2="12" y2="16"></line>
    <line x1="8" y1="12" x2="16" y2="12"></line>
  </svg>
);

export const removeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
    <line x1="8" y1="12" x2="16" y2="12"></line>
  </svg>
);

export const editIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polygon points="16 3 21 8 8 21 3 21 3 16 16 3"></polygon>
  </svg>
);

export const closeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="18" y1="6" x2="6" y2="18"></line>
    <line x1="6" y1="6" x2="18" y2="18"></line>
  </svg>
);

// export const searchIcon = (
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     width="24"
//     height="24"
//     viewBox="0 0 24 24"
//     fill="none"
//     stroke="currentColor"
//     strokeWidth="2"
//     strokeLinecap="round"
//     strokeLinejoin="round"
//   >
//     <circle cx="11" cy="11" r="8"></circle>
//     <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
//   </svg>
// );

// export const viewIcon = (
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     width="24"
//     height="24"
//     viewBox="0 0 24 24"
//     fill="none"
//     stroke="currentColor"
//     strokeWidth="2"
//     strokeLinecap="round"
//     strokeLinejoin="round"
//     className="feather feather-eye"
//   >
//     <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
//     <circle cx="12" cy="12" r="3"></circle>
//   </svg>
// );

export const sidebarToggleIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="17" y1="10" x2="3" y2="10"></line>
    <line x1="21" y1="6" x2="3" y2="6"></line>
    <line x1="21" y1="14" x2="3" y2="14"></line>
    <line x1="17" y1="18" x2="3" y2="18"></line>
  </svg>
);

export const logoutIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
    <polyline points="16 17 21 12 16 7"></polyline>
    <line x1="21" y1="12" x2="9" y2="12"></line>
  </svg>
);

// export const sidebarSmallIcon = (
//   <svg
//     className="small_sidebar_logo"
//     width="126"
//     height="107"
//     viewBox="0 0 126 107"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path d="M113.5 87L125 107H0.5L8.5 94.5L113.5 87Z" fill="#C11F3B" />
//     <path d="M33.5 52.5L94.5 76L15 82L33.5 52.5Z" fill="#C11F3B" />
//     <path d="M82 32.5L99.5 62L53.5 44.5L82 32.5Z" fill="#C11F3B" />
//     <path d="M63 0.5L77.5 25L40 39.5L63 0.5Z" fill="#C11F3B" />
//     <circle cx="107.5" cy="18.5" r="18.5" fill="url(#paint0_radial_324_4918)" />
//     <defs>
//       <radialGradient
//         id="paint0_radial_324_4918"
//         cx="0"
//         cy="0"
//         r="1"
//         gradientUnits="userSpaceOnUse"
//         gradientTransform="translate(117.069 2.55172) rotate(120.964) scale(18.5987)"
//       >
//         <stop stopColor="#FFEC0C" />
//         <stop offset="1" stopColor="#F79420" />
//       </radialGradient>
//     </defs>
//   </svg>
// );

export const dashboardIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
    <polyline points="9 22 9 12 15 12 15 22"></polyline>
  </svg>
);

// export const aboutIcon = (
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     width="24"
//     height="24"
//     viewBox="0 0 24 24"
//     fill="none"
//     stroke="currentColor"
//     strokeWidth="2"
//     strokeLinecap="round"
//     strokeLinejoin="round"
//   >
//     <circle cx="12" cy="12" r="10"></circle>
//     <line x1="12" y1="16" x2="12" y2="12"></line>
//     <line x1="12" y1="8" x2="12" y2="8"></line>
//   </svg>
// );

// export const privilegeIcon = (
//   <svg
//     viewBox="0 0 24 24"
//     width="24"
//     height="24"
//     stroke="currentColor"
//     strokeWidth="2"
//     fill="none"
//     strokeLinecap="round"
//     strokeLinejoin="round"
//     className="css-i6dzq1"
//   >
//     <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
//     <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
//   </svg>
// );

export const projectIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M12.89 1.45l8 4A2 2 0 0 1 22 7.24v9.53a2 2 0 0 1-1.11 1.79l-8 4a2 2 0 0 1-1.79 0l-8-4a2 2 0 0 1-1.1-1.8V7.24a2 2 0 0 1 1.11-1.79l8-4a2 2 0 0 1 1.78 0z"></path>
    <polyline points="2.32 6.16 12 11 21.68 6.16"></polyline>
    <line x1="12" y1="22.76" x2="12" y2="11"></line>
  </svg>
);

export const userIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
    <circle cx="9" cy="7" r="4"></circle>
    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
  </svg>
);

// export const propertiesIcon = (
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     width="24"
//     height="24"
//     viewBox="0 0 24 24"
//     fill="none"
//     stroke="currentColor"
//     strokeWidth="2"
//     strokeLinecap="round"
//     strokeLinejoin="round"
//   >
//     <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
//     <circle cx="12" cy="10" r="3"></circle>
//   </svg>
// );

// export const inquiryIcon = (
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     width="24"
//     height="24"
//     viewBox="0 0 24 24"
//     fill="none"
//     stroke="currentColor"
//     strokeWidth="2"
//     strokeLinecap="round"
//     strokeLinejoin="round"
//   >
//     <circle cx="12" cy="12" r="10"></circle>
//     <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
//     <line x1="12" y1="17" x2="12" y2="17"></line>
//   </svg>
// );

// export const complaintIcon = (
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     width="24"
//     height="24"
//     viewBox="0 0 24 24"
//     fill="none"
//     stroke="currentColor"
//     strokeWidth="2"
//     strokeLinecap="round"
//     strokeLinejoin="round"
//   >
//     <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
//     <line x1="12" y1="8" x2="12" y2="12"></line>
//     <line x1="12" y1="16" x2="12" y2="16"></line>
//   </svg>
// );

export const announcementIcon = (
  <svg
    viewBox="0 0 24 24"
    width="24"
    height="24"
    stroke="currentColor"
    strokeWidth="2"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="css-i6dzq1"
  >
    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
  </svg>
);

export const reFresh = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-refresh-ccw"
  >
    <polyline points="1 4 1 10 7 10"></polyline>
    <polyline points="23 20 23 14 17 14"></polyline>
    <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
  </svg>
);

// export const noticeIcon = (
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     width="24"
//     height="24"
//     viewBox="0 0 24 24"
//     fill="none"
//     stroke="currentColor"
//     strokeWidth="2"
//     strokeLinecap="round"
//     strokeLinejoin="round"
//   >
//     <circle cx="12" cy="12" r="10"></circle>
//     <line x1="12" y1="16" x2="12" y2="12"></line>
//     <line x1="12" y1="8" x2="12" y2="8"></line>
//   </svg>
// );

export const eyeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={2}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
    />
  </svg>
);

export const hideEyeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={2}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
    />
  </svg>
);

// export const dragDropIcon = (
//   <svg
//     width="30px"
//     height="30px"
//     viewBox="0 0 72 72"
//     id="emoji"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <g id="color" />
//     <g id="hair" />
//     <g id="skin" />
//     <g id="skin-shadow" />
//     <g id="line">
//       <line
//         x1="16"
//         x2="56"
//         y1="26"
//         y2="26"
//         fill="none"
//         stroke="#000000"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         strokeMiterlimit="10"
//         strokeWidth="2"
//       />
//       <line
//         x1="16"
//         x2="56"
//         y1="36"
//         y2="36"
//         fill="none"
//         stroke="#000000"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         strokeMiterlimit="10"
//         strokeWidth="2"
//       />
//       <line
//         x1="16"
//         x2="56"
//         y1="46"
//         y2="46"
//         fill="none"
//         stroke="#000000"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         strokeMiterlimit="10"
//         strokeWidth="2"
//       />
//     </g>
//   </svg>
// );

export const goNext = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={5.0}
    stroke="currentColor"
    className="w-10 h-10"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8.25 4.5l7.5 7.5-7.5 7.5"
    />
  </svg>
);

export const goBack = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={5.0}
    stroke="currentColor"
    className="w-10 h-10"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15.75 19.5L8.25 12l7.5-7.5"
    />
  </svg>
);

export const backBtnArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
    className="w-5 h-5"
  >
    <path
      fillRule="evenodd"
      d="M15.79 14.77a.75.75 0 01-1.06.02l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 111.04 1.08L11.832 10l3.938 3.71a.75.75 0 01.02 1.06zm-6 0a.75.75 0 01-1.06.02l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 111.04 1.08L5.832 10l3.938 3.71a.75.75 0 01.02 1.06z"
      clipRule="evenodd"
    />
  </svg>
);

export const deleteIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 26 26"
    stroke="currentColor"
    strokeWidth={1.8}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
    />
  </svg>
);

export const phoneIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth={2}
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-phone-call"
  >
    <path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
  </svg>
);

export const shredderIcon = (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5864 19.0604L24.1663 10.0035C25.3072 7.11723 22.173 4.40762 19.4819 5.95383L3.71932 15.0106C0.806504 16.6843 1.99401 21.1346 5.3534 21.1346H17.536C18.8821 21.1346 20.0915 20.3122 20.5864 19.0604Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M19.9316 7.4873H20.4274C20.5643 7.4873 20.6752 7.59828 20.6752 7.73517V7.87615C20.6752 7.98219 20.7464 8.07431 20.8443 8.11497C20.9423 8.15564 21.0564 8.14014 21.1314 8.06513L21.2311 7.96541C21.3279 7.86861 21.4848 7.86861 21.5816 7.96541L21.9321 8.31594C22.0289 8.41274 22.0289 8.56968 21.9321 8.66647L21.8324 8.76618C21.7574 8.84119 21.7419 8.95527 21.7826 9.05324C21.8233 9.15118 21.9154 9.22235 22.0214 9.22235L22.1624 9.22235C22.2993 9.22235 22.4103 9.33332 22.4103 9.47021V9.96594C22.4103 10.1028 22.2993 10.2138 22.1624 10.2138H22.0214C21.9154 10.2138 21.8233 10.285 21.7826 10.3829C21.7419 10.4809 21.7574 10.5949 21.8324 10.67L21.9322 10.7697C22.0289 10.8665 22.0289 11.0234 21.9322 11.1202L21.5816 11.4707C21.4848 11.5675 21.3279 11.5675 21.2311 11.4707L21.1314 11.371C21.0564 11.296 20.9423 11.2805 20.8443 11.3212C20.7464 11.3618 20.6752 11.454 20.6752 11.56V11.701C20.6752 11.8379 20.5643 11.9488 20.4274 11.9488H19.9316C19.7947 11.9488 19.6838 11.8379 19.6838 11.701V11.56C19.6838 11.454 19.6126 11.3618 19.5147 11.3212C19.4167 11.2805 19.3026 11.296 19.2276 11.371L19.1279 11.4707C19.0311 11.5675 18.8742 11.5675 18.7774 11.4707L18.4268 11.1202C18.33 11.0234 18.33 10.8665 18.4268 10.7697L18.5266 10.6699C18.6016 10.5949 18.6171 10.4809 18.5764 10.3829C18.5357 10.285 18.4436 10.2138 18.3376 10.2138H18.1966C18.0597 10.2138 17.9487 10.1028 17.9487 9.96594V9.47021C17.9487 9.33332 18.0597 9.22235 18.1966 9.22235L18.3376 9.22235C18.4436 9.22235 18.5357 9.15118 18.5764 9.05325C18.6171 8.95528 18.6016 8.8412 18.5266 8.7662L18.4268 8.66647C18.33 8.56968 18.33 8.41274 18.4268 8.31594L18.7774 7.96541C18.8742 7.86861 19.0311 7.86861 19.1279 7.96541L19.2276 8.06513C19.3026 8.14014 19.4167 8.15564 19.5147 8.11497C19.6126 8.07431 19.6838 7.98218 19.6838 7.87614V7.73517C19.6838 7.59828 19.7947 7.4873 19.9316 7.4873Z"
      stroke="currentColor"
      strokeWidth="0.5"
    />
    <path
      d="M20.9999 9.76924C20.9999 10.2224 20.6326 10.5898 20.1794 10.5898C19.7262 10.5898 19.3589 10.2224 19.3589 9.76924C19.3589 9.31609 19.7262 8.94873 20.1794 8.94873C20.6326 8.94873 20.9999 9.31609 20.9999 9.76924Z"
      stroke="currentColor"
      strokeWidth="0.5"
    />
    <path
      d="M17.0342 15.3076H17.5299C17.6668 15.3076 17.7778 15.4186 17.7778 15.5555V15.6965C17.7778 15.8025 17.8489 15.8946 17.9469 15.9353C18.0448 15.976 18.1589 15.9604 18.2339 15.8854L18.3336 15.7857C18.4304 15.6889 18.5874 15.6889 18.6842 15.7857L19.0347 16.1363C19.1315 16.2331 19.1315 16.39 19.0347 16.4868L18.935 16.5865C18.86 16.6615 18.8445 16.7756 18.8851 16.8736C18.9258 16.9715 19.0179 17.0427 19.124 17.0427L19.2649 17.0427C19.4018 17.0427 19.5128 17.1536 19.5128 17.2905V17.7862C19.5128 17.9231 19.4018 18.0341 19.2649 18.0341H19.124C19.0179 18.0341 18.9258 18.1053 18.8851 18.2032C18.8445 18.3012 18.86 18.4153 18.935 18.4903L19.0347 18.59C19.1315 18.6868 19.1315 18.8437 19.0347 18.9405L18.6842 19.291C18.5874 19.3878 18.4304 19.3878 18.3336 19.291L18.2339 19.1913C18.1589 19.1163 18.0448 19.1008 17.9469 19.1415C17.8489 19.1822 17.7778 19.2743 17.7778 19.3803V19.5213C17.7778 19.6582 17.6668 19.7692 17.5299 19.7692H17.0342C16.8973 19.7692 16.7863 19.6582 16.7863 19.5213V19.3803C16.7863 19.2743 16.7151 19.1822 16.6172 19.1415C16.5192 19.1008 16.4052 19.1163 16.3301 19.1913L16.2304 19.291C16.1336 19.3878 15.9767 19.3878 15.8799 19.291L15.5294 18.9405C15.4326 18.8437 15.4326 18.6868 15.5294 18.59L15.6291 18.4903C15.7041 18.4152 15.7196 18.3012 15.6789 18.2032C15.6383 18.1053 15.5461 18.0341 15.4401 18.0341H15.2991C15.1622 18.0341 15.0513 17.9231 15.0513 17.7863V17.2905C15.0513 17.1536 15.1622 17.0427 15.2991 17.0427L15.4401 17.0427C15.5461 17.0427 15.6383 16.9715 15.6789 16.8736C15.7196 16.7756 15.7041 16.6615 15.6291 16.5865L15.5294 16.4868C15.4326 16.39 15.4326 16.2331 15.5294 16.1363L15.8799 15.7857C15.9767 15.6889 16.1336 15.6889 16.2304 15.7857L16.3302 15.8854C16.4052 15.9605 16.5192 15.976 16.6172 15.9353C16.7151 15.8946 16.7863 15.8025 16.7863 15.6965V15.5555C16.7863 15.4186 16.8973 15.3076 17.0342 15.3076Z"
      stroke="currentColor"
      strokeWidth="0.5"
    />
    <path
      d="M18.1025 17.5896C18.1025 18.0427 17.7351 18.4101 17.2819 18.4101C16.8288 18.4101 16.4614 18.0427 16.4614 17.5896C16.4614 17.1364 16.8288 16.769 17.2819 16.769C17.7351 16.769 18.1025 17.1364 18.1025 17.5896Z"
      stroke="currentColor"
      strokeWidth="0.5"
    />
    <path
      d="M6.16235 15.5129H6.65808C6.79497 15.5129 6.90594 15.6239 6.90594 15.7608V15.9018C6.90594 16.0078 6.9771 16.0999 7.07504 16.1406C7.173 16.1813 7.28707 16.1658 7.36208 16.0908L7.4618 15.991C7.55859 15.8942 7.71553 15.8942 7.81233 15.991L8.16286 16.3416C8.25966 16.4384 8.25966 16.5953 8.16286 16.6921L8.06315 16.7918C7.98814 16.8668 7.97264 16.9809 8.01332 17.0789C8.05398 17.1768 8.14611 17.248 8.25215 17.248L8.39312 17.248C8.53001 17.248 8.64098 17.359 8.64098 17.4958V17.9916C8.64098 18.1285 8.53001 18.2394 8.39312 18.2394H8.25214C8.1461 18.2394 8.05398 18.3106 8.01332 18.4085C7.97264 18.5065 7.98815 18.6206 8.06316 18.6956L8.16286 18.7953C8.25966 18.8921 8.25966 19.049 8.16286 19.1458L7.81233 19.4964C7.71554 19.5932 7.5586 19.5932 7.4618 19.4964L7.36209 19.3967C7.28708 19.3216 7.17301 19.3061 7.07504 19.3468C6.97711 19.3875 6.90594 19.4796 6.90594 19.5856V19.7266C6.90594 19.8635 6.79497 19.9745 6.65808 19.9745H6.16235C6.02546 19.9745 5.91449 19.8635 5.91449 19.7266V19.5856C5.91449 19.4796 5.84332 19.3875 5.74538 19.3468C5.64741 19.3061 5.53333 19.3216 5.45832 19.3967L5.35861 19.4964C5.26181 19.5932 5.10487 19.5932 5.00808 19.4964L4.65754 19.1458C4.56075 19.049 4.56075 18.8921 4.65754 18.7953L4.75727 18.6956C4.83228 18.6206 4.84778 18.5065 4.8071 18.4085C4.76644 18.3106 4.67432 18.2394 4.56829 18.2394H4.42731C4.29042 18.2394 4.17944 18.1285 4.17944 17.9916V17.4958C4.17944 17.359 4.29042 17.248 4.42731 17.248L4.56828 17.248C4.67432 17.248 4.76645 17.1768 4.80711 17.0789C4.84778 16.9809 4.83228 16.8668 4.75727 16.7918L4.65755 16.6921C4.56075 16.5953 4.56075 16.4384 4.65755 16.3416L5.00808 15.991C5.10488 15.8942 5.26182 15.8942 5.35861 15.991L5.45833 16.0908C5.53334 16.1658 5.64741 16.1813 5.74538 16.1406C5.84332 16.0999 5.91449 16.0078 5.91449 15.9018V15.7608C5.91449 15.6239 6.02546 15.5129 6.16235 15.5129Z"
      stroke="currentColor"
      strokeWidth="0.5"
    />
    <path
      d="M7.23063 17.7949C7.23063 18.248 6.86327 18.6154 6.41011 18.6154C5.95696 18.6154 5.5896 18.248 5.5896 17.7949C5.5896 17.3417 5.95696 16.9744 6.41011 16.9744C6.86327 16.9744 7.23063 17.3417 7.23063 17.7949Z"
      stroke="currentColor"
      strokeWidth="0.5"
    />
  </svg>
);

export const dashboardUser = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35.012"
    height="18.235"
    viewBox="0 0 35.012 18.235"
  >
    <g id="user" transform="translate(-8 -12.583)">
      <g id="Group_1111" dataname="Group 1111" transform="translate(8 14.773)">
        <circle
          id="Ellipse_46"
          dataname="Ellipse 46"
          cx="2.917"
          cy="2.917"
          r="2.917"
          transform="translate(2.919)"
          fill="#ffffff"
          opacity="0.8"
        ></circle>
        <path
          id="Path_1020"
          dataname="Path 1020"
          d="M17.731,17.5A6.115,6.115,0,0,0,15.3,22.386v.365h-6.2A1.1,1.1,0,0,1,8,21.657v-.729a4.009,4.009,0,0,1,4.011-4.011h3.648A4,4,0,0,1,17.731,17.5Z"
          transform="translate(-8 -9.623)"
          fill="#ffffff"
          opacity="0.8"
        ></path>
        <circle
          id="Ellipse_47"
          dataname="Ellipse 47"
          cx="2.917"
          cy="2.917"
          r="2.917"
          transform="translate(26.259)"
          fill="#ffffff"
          opacity="0.8"
        ></circle>
        <path
          id="Path_1021"
          dataname="Path 1021"
          d="M29.283,20.929v.729a1.1,1.1,0,0,1-1.094,1.094h-6.2v-.365A6.112,6.112,0,0,0,19.554,17.5a3.978,3.978,0,0,1,2.072-.584h3.648A4.012,4.012,0,0,1,29.283,20.929Z"
          transform="translate(5.729 -9.625)"
          fill="#ffffff"
          opacity="0.8"
        ></path>
      </g>
      <circle
        id="Ellipse_48"
        dataname="Ellipse 48"
        cx="4"
        cy="4"
        r="4"
        transform="translate(21 12.583)"
        fill="#ffffff"
      ></circle>
      <path
        id="Path_1022"
        dataname="Path 1022"
        d="M24.369,17.583H16.344a4.015,4.015,0,0,0-4.011,4.011v2.188a1.1,1.1,0,0,0,1.094,1.094H27.286a1.1,1.1,0,0,0,1.094-1.094V21.594A4.015,4.015,0,0,0,24.369,17.583Z"
        transform="translate(5.149 5.941)"
        fill="#ffffff"
      ></path>
    </g>
  </svg>
);

export const dashboardShredderIcon = (
  <svg
    width="30"
    height="30"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5864 19.0604L24.1663 10.0035C25.3072 7.11723 22.173 4.40762 19.4819 5.95383L3.71932 15.0106C0.806504 16.6843 1.99401 21.1346 5.3534 21.1346H17.536C18.8821 21.1346 20.0915 20.3122 20.5864 19.0604Z"
      stroke="#ffffff"
      strokeWidth="1.5"
    />
    <path
      d="M19.9316 7.4873H20.4274C20.5643 7.4873 20.6752 7.59828 20.6752 7.73517V7.87615C20.6752 7.98219 20.7464 8.07431 20.8443 8.11497C20.9423 8.15564 21.0564 8.14014 21.1314 8.06513L21.2311 7.96541C21.3279 7.86861 21.4848 7.86861 21.5816 7.96541L21.9321 8.31594C22.0289 8.41274 22.0289 8.56968 21.9321 8.66647L21.8324 8.76618C21.7574 8.84119 21.7419 8.95527 21.7826 9.05324C21.8233 9.15118 21.9154 9.22235 22.0214 9.22235L22.1624 9.22235C22.2993 9.22235 22.4103 9.33332 22.4103 9.47021V9.96594C22.4103 10.1028 22.2993 10.2138 22.1624 10.2138H22.0214C21.9154 10.2138 21.8233 10.285 21.7826 10.3829C21.7419 10.4809 21.7574 10.5949 21.8324 10.67L21.9322 10.7697C22.0289 10.8665 22.0289 11.0234 21.9322 11.1202L21.5816 11.4707C21.4848 11.5675 21.3279 11.5675 21.2311 11.4707L21.1314 11.371C21.0564 11.296 20.9423 11.2805 20.8443 11.3212C20.7464 11.3618 20.6752 11.454 20.6752 11.56V11.701C20.6752 11.8379 20.5643 11.9488 20.4274 11.9488H19.9316C19.7947 11.9488 19.6838 11.8379 19.6838 11.701V11.56C19.6838 11.454 19.6126 11.3618 19.5147 11.3212C19.4167 11.2805 19.3026 11.296 19.2276 11.371L19.1279 11.4707C19.0311 11.5675 18.8742 11.5675 18.7774 11.4707L18.4268 11.1202C18.33 11.0234 18.33 10.8665 18.4268 10.7697L18.5266 10.6699C18.6016 10.5949 18.6171 10.4809 18.5764 10.3829C18.5357 10.285 18.4436 10.2138 18.3376 10.2138H18.1966C18.0597 10.2138 17.9487 10.1028 17.9487 9.96594V9.47021C17.9487 9.33332 18.0597 9.22235 18.1966 9.22235L18.3376 9.22235C18.4436 9.22235 18.5357 9.15118 18.5764 9.05325C18.6171 8.95528 18.6016 8.8412 18.5266 8.7662L18.4268 8.66647C18.33 8.56968 18.33 8.41274 18.4268 8.31594L18.7774 7.96541C18.8742 7.86861 19.0311 7.86861 19.1279 7.96541L19.2276 8.06513C19.3026 8.14014 19.4167 8.15564 19.5147 8.11497C19.6126 8.07431 19.6838 7.98218 19.6838 7.87614V7.73517C19.6838 7.59828 19.7947 7.4873 19.9316 7.4873Z"
      stroke="#ffffff"
      strokeWidth="0.5"
    />
    <path
      d="M20.9999 9.76924C20.9999 10.2224 20.6326 10.5898 20.1794 10.5898C19.7262 10.5898 19.3589 10.2224 19.3589 9.76924C19.3589 9.31609 19.7262 8.94873 20.1794 8.94873C20.6326 8.94873 20.9999 9.31609 20.9999 9.76924Z"
      stroke="#ffffff"
      strokeWidth="0.5"
    />
    <path
      d="M17.0342 15.3076H17.5299C17.6668 15.3076 17.7778 15.4186 17.7778 15.5555V15.6965C17.7778 15.8025 17.8489 15.8946 17.9469 15.9353C18.0448 15.976 18.1589 15.9604 18.2339 15.8854L18.3336 15.7857C18.4304 15.6889 18.5874 15.6889 18.6842 15.7857L19.0347 16.1363C19.1315 16.2331 19.1315 16.39 19.0347 16.4868L18.935 16.5865C18.86 16.6615 18.8445 16.7756 18.8851 16.8736C18.9258 16.9715 19.0179 17.0427 19.124 17.0427L19.2649 17.0427C19.4018 17.0427 19.5128 17.1536 19.5128 17.2905V17.7862C19.5128 17.9231 19.4018 18.0341 19.2649 18.0341H19.124C19.0179 18.0341 18.9258 18.1053 18.8851 18.2032C18.8445 18.3012 18.86 18.4153 18.935 18.4903L19.0347 18.59C19.1315 18.6868 19.1315 18.8437 19.0347 18.9405L18.6842 19.291C18.5874 19.3878 18.4304 19.3878 18.3336 19.291L18.2339 19.1913C18.1589 19.1163 18.0448 19.1008 17.9469 19.1415C17.8489 19.1822 17.7778 19.2743 17.7778 19.3803V19.5213C17.7778 19.6582 17.6668 19.7692 17.5299 19.7692H17.0342C16.8973 19.7692 16.7863 19.6582 16.7863 19.5213V19.3803C16.7863 19.2743 16.7151 19.1822 16.6172 19.1415C16.5192 19.1008 16.4052 19.1163 16.3301 19.1913L16.2304 19.291C16.1336 19.3878 15.9767 19.3878 15.8799 19.291L15.5294 18.9405C15.4326 18.8437 15.4326 18.6868 15.5294 18.59L15.6291 18.4903C15.7041 18.4152 15.7196 18.3012 15.6789 18.2032C15.6383 18.1053 15.5461 18.0341 15.4401 18.0341H15.2991C15.1622 18.0341 15.0513 17.9231 15.0513 17.7863V17.2905C15.0513 17.1536 15.1622 17.0427 15.2991 17.0427L15.4401 17.0427C15.5461 17.0427 15.6383 16.9715 15.6789 16.8736C15.7196 16.7756 15.7041 16.6615 15.6291 16.5865L15.5294 16.4868C15.4326 16.39 15.4326 16.2331 15.5294 16.1363L15.8799 15.7857C15.9767 15.6889 16.1336 15.6889 16.2304 15.7857L16.3302 15.8854C16.4052 15.9605 16.5192 15.976 16.6172 15.9353C16.7151 15.8946 16.7863 15.8025 16.7863 15.6965V15.5555C16.7863 15.4186 16.8973 15.3076 17.0342 15.3076Z"
      stroke="#ffffff"
      strokeWidth="0.5"
    />
    <path
      d="M18.1025 17.5896C18.1025 18.0427 17.7351 18.4101 17.2819 18.4101C16.8288 18.4101 16.4614 18.0427 16.4614 17.5896C16.4614 17.1364 16.8288 16.769 17.2819 16.769C17.7351 16.769 18.1025 17.1364 18.1025 17.5896Z"
      stroke="#ffffff"
      strokeWidth="0.5"
    />
    <path
      d="M6.16235 15.5129H6.65808C6.79497 15.5129 6.90594 15.6239 6.90594 15.7608V15.9018C6.90594 16.0078 6.9771 16.0999 7.07504 16.1406C7.173 16.1813 7.28707 16.1658 7.36208 16.0908L7.4618 15.991C7.55859 15.8942 7.71553 15.8942 7.81233 15.991L8.16286 16.3416C8.25966 16.4384 8.25966 16.5953 8.16286 16.6921L8.06315 16.7918C7.98814 16.8668 7.97264 16.9809 8.01332 17.0789C8.05398 17.1768 8.14611 17.248 8.25215 17.248L8.39312 17.248C8.53001 17.248 8.64098 17.359 8.64098 17.4958V17.9916C8.64098 18.1285 8.53001 18.2394 8.39312 18.2394H8.25214C8.1461 18.2394 8.05398 18.3106 8.01332 18.4085C7.97264 18.5065 7.98815 18.6206 8.06316 18.6956L8.16286 18.7953C8.25966 18.8921 8.25966 19.049 8.16286 19.1458L7.81233 19.4964C7.71554 19.5932 7.5586 19.5932 7.4618 19.4964L7.36209 19.3967C7.28708 19.3216 7.17301 19.3061 7.07504 19.3468C6.97711 19.3875 6.90594 19.4796 6.90594 19.5856V19.7266C6.90594 19.8635 6.79497 19.9745 6.65808 19.9745H6.16235C6.02546 19.9745 5.91449 19.8635 5.91449 19.7266V19.5856C5.91449 19.4796 5.84332 19.3875 5.74538 19.3468C5.64741 19.3061 5.53333 19.3216 5.45832 19.3967L5.35861 19.4964C5.26181 19.5932 5.10487 19.5932 5.00808 19.4964L4.65754 19.1458C4.56075 19.049 4.56075 18.8921 4.65754 18.7953L4.75727 18.6956C4.83228 18.6206 4.84778 18.5065 4.8071 18.4085C4.76644 18.3106 4.67432 18.2394 4.56829 18.2394H4.42731C4.29042 18.2394 4.17944 18.1285 4.17944 17.9916V17.4958C4.17944 17.359 4.29042 17.248 4.42731 17.248L4.56828 17.248C4.67432 17.248 4.76645 17.1768 4.80711 17.0789C4.84778 16.9809 4.83228 16.8668 4.75727 16.7918L4.65755 16.6921C4.56075 16.5953 4.56075 16.4384 4.65755 16.3416L5.00808 15.991C5.10488 15.8942 5.26182 15.8942 5.35861 15.991L5.45833 16.0908C5.53334 16.1658 5.64741 16.1813 5.74538 16.1406C5.84332 16.0999 5.91449 16.0078 5.91449 15.9018V15.7608C5.91449 15.6239 6.02546 15.5129 6.16235 15.5129Z"
      stroke="#ffffff"
      strokeWidth="0.5"
    />
    <path
      d="M7.23063 17.7949C7.23063 18.248 6.86327 18.6154 6.41011 18.6154C5.95696 18.6154 5.5896 18.248 5.5896 17.7949C5.5896 17.3417 5.95696 16.9744 6.41011 16.9744C6.86327 16.9744 7.23063 17.3417 7.23063 17.7949Z"
      stroke="#ffffff"
      strokeWidth="0.5"
    />
  </svg>
);

export const dashboardGearIcon = (
  <svg
    fill="#ffffff"
    width="32px"
    height="32px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M22.36,9.37l-.75-.14a9.89,9.89,0,0,0-.86-2.06l.44-.63A2,2,0,0,0,21,4L20,3a2,2,0,0,0-2.55-.23l-.63.44a9.89,9.89,0,0,0-2.06-.86l-.14-.75a2,2,0,0,0-2-1.64H11.33a2,2,0,0,0-2,1.64l-.14.75a9.89,9.89,0,0,0-2.06.86l-.63-.44A2,2,0,0,0,4,3L3,4a2,2,0,0,0-.23,2.55l.44.63a9.89,9.89,0,0,0-.86,2.06l-.75.14a2,2,0,0,0-1.64,2v1.33a2,2,0,0,0,1.64,2l.75.14a9.89,9.89,0,0,0,.86,2.06l-.44.63A2,2,0,0,0,3,20L4,21a2,2,0,0,0,2.55.23l.63-.44a9.89,9.89,0,0,0,2.06.86l.14.75a2,2,0,0,0,2,1.64h1.33a2,2,0,0,0,2-1.64l.14-.75a9.89,9.89,0,0,0,2.06-.86l.63.44A2,2,0,0,0,20,21L21,20a2,2,0,0,0,.23-2.55l-.44-.63a9.89,9.89,0,0,0,.86-2.06l.75-.14a2,2,0,0,0,1.64-2V11.33A2,2,0,0,0,22.36,9.37ZM22,12.67l-1.38.25a1,1,0,0,0-.8.77,7.93,7.93,0,0,1-1.1,2.64,1,1,0,0,0,0,1.11l.8,1.16-.94.94-1.16-.8a1,1,0,0,0-1.11,0,7.93,7.93,0,0,1-2.64,1.1,1,1,0,0,0-.77.8L12.67,22H11.33l-.25-1.38a1,1,0,0,0-.77-.8,7.93,7.93,0,0,1-2.64-1.1,1,1,0,0,0-1.11,0l-1.16.8-.94-.94.8-1.16a1,1,0,0,0,0-1.11,7.93,7.93,0,0,1-1.1-2.64,1,1,0,0,0-.8-.77L2,12.67V11.33l1.38-.25a1,1,0,0,0,.8-.77,7.93,7.93,0,0,1,1.1-2.64,1,1,0,0,0,0-1.11L4.46,5.4l.94-.94,1.16.8a1,1,0,0,0,1.11,0,7.93,7.93,0,0,1,2.64-1.1,1,1,0,0,0,.77-.8L11.33,2h1.33l.25,1.38a1,1,0,0,0,.77.8,7.93,7.93,0,0,1,2.64,1.1,1,1,0,0,0,1.11,0l1.16-.8.94.94-.8,1.16a1,1,0,0,0,0,1.11,7.93,7.93,0,0,1,1.1,2.64,1,1,0,0,0,.8.77l1.38.25Z" />
    <path d="M12,7a5,5,0,1,0,5,5A5,5,0,0,0,12,7Zm0,8a3,3,0,1,1,3-3A3,3,0,0,1,12,15Z" />
  </svg>
);

export const checkIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
      fill="#D1E7DD"
      stroke="#0F5132"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 12.75L11.25 15L15 9.75"
      stroke="#0F5132"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const crossIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
      fill="#F8D7DA"
      stroke="#842029"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.75 9.75L14.25 14.25M14.25 9.75L9.75 14.25"
      stroke="#842029"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const logoSvg = (
  <svg viewBox="0 0 194 79" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.5951 21.478H51.2951L47.9621 37.269L38.2771 37.298L41.5951 21.478ZM107.539 2.00605L87.9111 2.00305L86.6661 8.01705C84.8481 16.574 85.6441 15.154 82.9721 15.154C80.4301 15.154 74.0861 15.297 72.3321 15.122L75.0621 2.08905L55.7651 1.99805C55.2372 2.98877 54.9129 4.07508 54.8111 5.19305C54.2621 7.75105 53.2491 13.193 52.6501 15.122L38.9881 15.155C34.7731 15.155 29.7961 14.618 26.4131 16.393C24.9312 17.1714 23.7019 18.356 22.8691 19.808C21.7271 21.784 20.6301 28.144 20.0541 30.738C18.9281 35.798 16.3581 43.298 25.0721 43.584C29.5591 43.734 34.3211 43.613 38.8351 43.613C47.9684 43.613 57.0998 43.62 66.2291 43.634L70.9211 21.541L83.7301 21.516L81.6991 31.274C79.4541 40.874 85.4861 43.705 92.6481 43.845C95.6711 43.845 102.436 43.788 105.459 43.788C108.023 43.788 108.589 43.841 109.722 42.866C111.835 40.832 111.435 37.683 108.945 37.491C106.28 37.285 102.754 37.491 99.9901 37.481L103.398 21.525L122.45 21.517L123.629 43.591H144.042C151.963 43.591 159.651 44.827 164.478 38.677C165.994 36.745 168.398 31.299 169.66 28.754C170.26 27.554 175.733 16.305 176.192 15.117L156.892 15.088L146.159 37.288L142.988 37.305L141.707 15.284L104.733 15.084L107.539 2.00605Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2024 67.7908C10.9484 67.5168 11.0024 67.6428 11.0134 66.7438C11.0384 64.3438 11.0034 57.8708 10.9764 51.1258C10.9414 42.0828 10.9224 12.0178 11.0874 11.2008L50.0164 11.0868L51.9624 2.23976L11.6244 2.21576C7.21938 2.21576 4.64138 4.16476 3.10738 7.00076C1.51638 9.94076 2.10738 15.6838 2.10738 19.4478V66.7478C2.10738 69.1058 2.22638 70.4578 3.25838 72.2478C5.24738 75.7008 8.29938 76.7838 12.2344 76.7838H179.713C185.072 76.7838 187.078 76.6578 189.855 73.5588C192.468 70.6428 191.955 66.7748 191.955 62.2128V14.9128C191.955 9.49576 192.095 7.44376 188.927 4.48276C185.905 1.65676 182.545 2.21576 177.748 2.21576H124.238C119.889 2.21576 115.27 2.06676 110.954 2.23276L109.048 11.1868L182.921 11.1938L182.928 48.2158L182.936 67.6988L32.6364 67.7988C25.5564 67.7988 18.2554 67.9728 11.2034 67.7888"
      fill="currentColor"
    />
    <path
      d="M26.9548 55.8703L24.4548 59.0353H22.6798L21.6378 55.8703L20.4977 60.8073H18.2188L20.2648 51.9453H22.6698L24.1198 56.6293L27.7328 51.9453H30.1398L28.0938 60.8033H25.8148L26.9548 55.8703Z"
      fill="currentColor"
    />
    <path
      d="M38.9439 58.4026C38.82 59.1447 38.4514 59.824 37.8969 60.3326C37.2973 60.7657 36.5662 60.9777 35.8279 60.9326H32.6619C32.3373 60.9747 32.0074 60.9426 31.6969 60.8386C31.3865 60.7347 31.1038 60.5617 30.8699 60.3326C30.7054 60.0416 30.6058 59.7183 30.5782 59.3851C30.5506 59.052 30.5955 58.7167 30.7099 58.4026L31.6449 54.3516C31.7686 53.6091 32.1372 52.9294 32.6919 52.4206C33.2918 51.9873 34.0233 51.7752 34.7619 51.8206H37.9269C38.2516 51.7786 38.5815 51.8108 38.8919 51.9148C39.2022 52.0187 39.485 52.1916 39.7189 52.4206C39.8828 52.7121 39.9816 53.0357 40.0085 53.369C40.0355 53.7023 39.9899 54.0376 39.8749 54.3516L38.9439 58.4026ZM37.5999 54.3516C37.7166 53.8449 37.5219 53.5916 37.0159 53.5916H34.8639C34.6405 53.5749 34.4191 53.6443 34.2453 53.7856C34.0714 53.9269 33.9582 54.1295 33.9289 54.3516L32.9939 58.4026C32.8773 58.9086 33.0719 59.1616 33.5779 59.1616H35.7299C35.9532 59.1784 36.1745 59.1091 36.3483 58.968C36.5222 58.8268 36.6354 58.6246 36.6649 58.4026L37.5999 54.3516Z"
      fill="currentColor"
    />
    <path
      d="M44.7531 60.8024H42.4751L44.1121 53.7124H41.4531L41.8621 51.9395H49.4621L49.0531 53.7124H46.3941L44.7531 60.8024Z"
      fill="currentColor"
    />
    <path
      d="M57.6274 58.4026C57.5034 59.1447 57.1349 59.824 56.5804 60.3326C55.9808 60.7657 55.2497 60.9777 54.5114 60.9326H51.3464C51.0218 60.9747 50.6918 60.9426 50.3814 60.8386C50.071 60.7347 49.7882 60.5617 49.5544 60.3326C49.3906 60.0412 49.292 59.7178 49.2652 59.3846C49.2385 59.0514 49.2843 58.7164 49.3994 58.4026L50.3344 54.3516C50.4581 53.6091 50.8267 52.9294 51.3814 52.4206C51.9813 51.9873 52.7128 51.7752 53.4514 51.8206H56.6164C56.941 51.7786 57.2709 51.8108 57.5813 51.9148C57.8917 52.0187 58.1745 52.1916 58.4084 52.4206C58.5723 52.7121 58.6711 53.0357 58.698 53.369C58.7249 53.7023 58.6794 54.0376 58.5644 54.3516L57.6274 58.4026ZM56.2834 54.3516C56.4001 53.8449 56.2054 53.5916 55.6994 53.5916H53.5474C53.324 53.5749 53.1026 53.6443 52.9287 53.7856C52.7549 53.9269 52.6417 54.1295 52.6124 54.3516L51.6774 58.4026C51.5607 58.9086 51.7554 59.1616 52.2614 59.1616H54.4134C54.6367 59.1784 54.8579 59.1091 55.0318 58.968C55.2056 58.8268 55.3189 58.6246 55.3484 58.4026L56.2834 54.3516Z"
      fill="currentColor"
    />
    <path
      d="M61.1808 51.9457H66.4978C66.8224 51.9036 67.1524 51.9358 67.4628 52.0397C67.7732 52.1436 68.0559 52.3166 68.2898 52.5457C68.4537 52.8372 68.5525 53.1607 68.5794 53.4941C68.6063 53.8274 68.5608 54.1626 68.4458 54.4767L68.1828 55.6156C68.0702 56.2086 67.8221 56.7674 67.4578 57.2487C67.1275 57.6425 66.6747 57.9143 66.1718 58.0207L67.1118 60.8057H64.6428L63.8008 58.1467H62.0258L61.4118 60.8057H59.1328L61.1808 51.9457ZM66.1668 54.4776C66.2835 53.971 66.0888 53.718 65.5828 53.7187H63.0508L62.4368 56.3777H64.9688C65.1922 56.3944 65.4136 56.325 65.5875 56.1836C65.7614 56.0423 65.8745 55.8398 65.9038 55.6177L66.1668 54.4776Z"
      fill="currentColor"
    />
    <path
      d="M80.5106 60.6794C78.5892 60.8481 77.0086 60.9324 75.7686 60.9324C75.4613 60.9707 75.1493 60.9382 74.8566 60.8373C74.5638 60.7364 74.298 60.5699 74.0796 60.3504C73.9216 60.0766 73.8253 59.7716 73.7975 59.4567C73.7697 59.1418 73.811 58.8247 73.9186 58.5274L74.8536 54.4764C74.9774 53.734 75.3459 53.0543 75.9005 52.5454C76.5001 52.1123 77.2313 51.9003 77.9695 51.9454H82.5266L82.1176 53.7184H78.0665C77.8433 53.7017 77.622 53.7709 77.4482 53.9121C77.2743 54.0532 77.1611 54.2555 77.1316 54.4774L76.1955 58.5284C76.1705 58.6052 76.1633 58.6867 76.1743 58.7668C76.1854 58.8468 76.2146 58.9233 76.2596 58.9904C76.3075 59.049 76.3689 59.0952 76.4386 59.125C76.5082 59.1548 76.584 59.1673 76.6596 59.1614C76.8369 59.1614 77.0316 59.1594 77.2436 59.1554C77.4556 59.1514 77.6766 59.1451 77.9066 59.1364L78.6066 59.1114C78.8406 59.1034 79.1442 59.0864 79.5176 59.0604C79.8909 59.0344 80.3556 59.0048 80.9116 58.9714L80.5106 60.6794Z"
      fill="currentColor"
    />
    <path
      d="M90.8233 58.4026C90.6993 59.1447 90.3308 59.824 89.7763 60.3326C89.1767 60.7657 88.4456 60.9777 87.7073 60.9326H84.5423C84.2177 60.9747 83.8878 60.9426 83.5773 60.8386C83.2669 60.7347 82.9841 60.5617 82.7503 60.3326C82.5864 60.0413 82.4876 59.7179 82.4606 59.3847C82.4337 59.0515 82.4793 58.7165 82.5943 58.4026L83.5293 54.3516C83.653 53.6091 84.0216 52.9294 84.5763 52.4206C85.1762 51.9873 85.9077 51.7752 86.6463 51.8206H89.8113C90.1359 51.7786 90.4659 51.8108 90.7763 51.9148C91.0866 52.0187 91.3694 52.1916 91.6033 52.4206C91.7672 52.7121 91.866 53.0357 91.8929 53.369C91.9199 53.7023 91.8743 54.0376 91.7593 54.3516L90.8233 58.4026ZM89.4793 54.3516C89.596 53.8449 89.4013 53.5916 88.8953 53.5916H86.7433C86.5199 53.5749 86.2985 53.6443 86.1246 53.7856C85.9508 53.9269 85.8376 54.1295 85.8083 54.3516L84.8733 58.4026C84.7567 58.9086 84.9513 59.1616 85.4573 59.1616H87.6093C87.8333 59.1794 88.0555 59.1106 88.2302 58.9694C88.405 58.8282 88.5188 58.6253 88.5483 58.4026L89.4793 54.3516Z"
      fill="currentColor"
    />
    <path
      d="M94.3731 51.9457H99.6901C100.015 51.9036 100.345 51.9358 100.655 52.0397C100.965 52.1436 101.248 52.3166 101.482 52.5457C101.646 52.8372 101.745 53.1607 101.772 53.4941C101.799 53.8274 101.753 54.1626 101.638 54.4767L101.375 55.6156C101.262 56.2086 101.014 56.7674 100.65 57.2487C100.32 57.6425 99.867 57.9143 99.3641 58.0207L100.304 60.8057H97.8351L96.9931 58.1467H95.2211L94.6071 60.8057H92.3281L94.3731 51.9457ZM99.3591 54.4776C99.4758 53.971 99.2811 53.718 98.7751 53.7187H96.2431L95.6291 56.3777H98.1611C98.3846 56.3944 98.6059 56.325 98.7798 56.1836C98.9537 56.0423 99.0669 55.8398 99.0961 55.6177L99.3591 54.4776Z"
      fill="currentColor"
    />
    <path
      d="M104.289 51.9456H109.606C109.931 51.9036 110.261 51.9358 110.571 52.0398C110.881 52.1437 111.164 52.3166 111.398 52.5456C111.562 52.8371 111.661 53.1607 111.688 53.494C111.715 53.8273 111.669 54.1625 111.554 54.4766L111.261 55.7426C111.137 56.485 110.769 57.1647 110.214 57.6736C109.615 58.1067 108.883 58.3187 108.145 58.2736H105.106L104.521 60.8056H102.242L104.289 51.9456ZM109.275 54.4776C109.392 53.9709 109.197 53.7179 108.691 53.7186H106.159L105.516 56.5036H108.048C108.272 56.5203 108.493 56.4509 108.667 56.3096C108.841 56.1683 108.954 55.9657 108.983 55.7436L109.275 54.4776Z"
      fill="currentColor"
    />
    <path
      d="M120.558 58.4026C120.434 59.1447 120.065 59.824 119.511 60.3326C118.911 60.7657 118.18 60.9777 117.442 60.9326H114.277C113.952 60.9747 113.622 60.9426 113.312 60.8386C113.001 60.7347 112.719 60.5617 112.485 60.3326C112.321 60.0413 112.222 59.7179 112.195 59.3847C112.168 59.0515 112.214 58.7165 112.329 58.4026L113.264 54.3516C113.387 53.6091 113.756 52.9294 114.311 52.4206C114.911 51.9873 115.642 51.7752 116.381 51.8206H119.546C119.87 51.7786 120.2 51.8108 120.511 51.9148C120.821 52.0187 121.104 52.1916 121.338 52.4206C121.502 52.7121 121.6 53.0357 121.627 53.369C121.654 53.7023 121.609 54.0376 121.494 54.3516L120.558 58.4026ZM119.214 54.3516C119.33 53.8449 119.136 53.5916 118.63 53.5916H116.478C116.254 53.5749 116.033 53.6443 115.859 53.7856C115.685 53.9269 115.572 54.1295 115.543 54.3516L114.608 58.4026C114.491 58.9086 114.686 59.1616 115.192 59.1616H117.344C117.567 59.1784 117.788 59.1091 117.962 58.968C118.136 58.8268 118.249 58.6246 118.279 58.4026L119.214 54.3516Z"
      fill="currentColor"
    />
    <path
      d="M124.112 51.9456H129.429C129.754 51.9036 130.084 51.9358 130.394 52.0398C130.705 52.1437 130.987 52.3166 131.221 52.5456C131.385 52.8371 131.484 53.1607 131.511 53.494C131.538 53.8273 131.492 54.1625 131.377 54.4766L131.114 55.6156C131.002 56.2085 130.754 56.7674 130.389 57.2486C130.059 57.6424 129.606 57.9142 129.103 58.0206L130.043 60.8056H127.577L126.735 58.1466H124.963L124.349 60.8056H122.07L124.112 51.9456ZM129.098 54.4776C129.215 53.9709 129.02 53.7179 128.514 53.7186H125.977L125.363 56.3776H127.895C128.119 56.3943 128.34 56.3249 128.514 56.1836C128.688 56.0423 128.801 55.8398 128.83 55.6176L129.098 54.4776Z"
      fill="currentColor"
    />
    <path
      d="M133.562 60.8034H131.094L136.494 51.9414H139.026L140.335 60.8034H137.866L137.666 59.2204H134.501L133.562 60.8034ZM135.447 57.5754H137.472L137.146 54.6004L135.447 57.5754Z"
      fill="currentColor"
    />
    <path
      d="M145.184 60.8024H142.903L144.54 53.7124H141.883L142.292 51.9395H149.892L149.483 53.7124H146.824L145.184 60.8024Z"
      fill="currentColor"
    />
    <path
      d="M153.705 60.8024H149.148L149.557 59.0294H150.696L151.923 53.7124H150.784L151.193 51.9395H155.75L155.341 53.7124H154.202L152.975 59.0294H154.114L153.705 60.8024Z"
      fill="currentColor"
    />
    <path
      d="M164.175 58.4026C164.051 59.1447 163.682 59.8241 163.128 60.3326C162.529 60.7662 161.797 60.9782 161.059 60.9326H157.894C157.569 60.9747 157.239 60.9426 156.929 60.8386C156.618 60.7347 156.336 60.5617 156.102 60.3326C155.938 60.0413 155.839 59.7179 155.812 59.3847C155.785 59.0515 155.831 58.7165 155.946 58.4026L156.881 54.3516C157.005 53.6091 157.373 52.9294 157.928 52.4206C158.528 51.9873 159.259 51.7752 159.998 51.8206H163.163C163.487 51.7786 163.817 51.8108 164.128 51.9148C164.438 52.0187 164.721 52.1916 164.955 52.4206C165.119 52.7121 165.217 53.0357 165.244 53.369C165.271 53.7023 165.226 54.0375 165.111 54.3516L164.175 58.4026ZM162.831 54.3516C162.948 53.8449 162.753 53.5916 162.247 53.5916H160.095C159.871 53.5749 159.65 53.6443 159.476 53.7856C159.302 53.9269 159.189 54.1295 159.16 54.3516L158.225 58.4026C158.108 58.9086 158.303 59.1616 158.809 59.1616H160.961C161.184 59.1784 161.405 59.1091 161.579 58.968C161.753 58.8268 161.866 58.6246 161.896 58.4026L162.831 54.3516Z"
      fill="currentColor"
    />
    <path
      d="M173.402 60.8034H170.997L169.186 55.4864L167.959 60.8034H165.68L167.726 51.9414H170.13L171.941 57.2584L173.169 51.9414H175.448L173.402 60.8034Z"
      fill="currentColor"
    />
  </svg>
);

export const gearIcon = (
  <svg
    fill="currentColor"
    width="800px"
    height="800px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M22.36,9.37l-.75-.14a9.89,9.89,0,0,0-.86-2.06l.44-.63A2,2,0,0,0,21,4L20,3a2,2,0,0,0-2.55-.23l-.63.44a9.89,9.89,0,0,0-2.06-.86l-.14-.75a2,2,0,0,0-2-1.64H11.33a2,2,0,0,0-2,1.64l-.14.75a9.89,9.89,0,0,0-2.06.86l-.63-.44A2,2,0,0,0,4,3L3,4a2,2,0,0,0-.23,2.55l.44.63a9.89,9.89,0,0,0-.86,2.06l-.75.14a2,2,0,0,0-1.64,2v1.33a2,2,0,0,0,1.64,2l.75.14a9.89,9.89,0,0,0,.86,2.06l-.44.63A2,2,0,0,0,3,20L4,21a2,2,0,0,0,2.55.23l.63-.44a9.89,9.89,0,0,0,2.06.86l.14.75a2,2,0,0,0,2,1.64h1.33a2,2,0,0,0,2-1.64l.14-.75a9.89,9.89,0,0,0,2.06-.86l.63.44A2,2,0,0,0,20,21L21,20a2,2,0,0,0,.23-2.55l-.44-.63a9.89,9.89,0,0,0,.86-2.06l.75-.14a2,2,0,0,0,1.64-2V11.33A2,2,0,0,0,22.36,9.37ZM22,12.67l-1.38.25a1,1,0,0,0-.8.77,7.93,7.93,0,0,1-1.1,2.64,1,1,0,0,0,0,1.11l.8,1.16-.94.94-1.16-.8a1,1,0,0,0-1.11,0,7.93,7.93,0,0,1-2.64,1.1,1,1,0,0,0-.77.8L12.67,22H11.33l-.25-1.38a1,1,0,0,0-.77-.8,7.93,7.93,0,0,1-2.64-1.1,1,1,0,0,0-1.11,0l-1.16.8-.94-.94.8-1.16a1,1,0,0,0,0-1.11,7.93,7.93,0,0,1-1.1-2.64,1,1,0,0,0-.8-.77L2,12.67V11.33l1.38-.25a1,1,0,0,0,.8-.77,7.93,7.93,0,0,1,1.1-2.64,1,1,0,0,0,0-1.11L4.46,5.4l.94-.94,1.16.8a1,1,0,0,0,1.11,0,7.93,7.93,0,0,1,2.64-1.1,1,1,0,0,0,.77-.8L11.33,2h1.33l.25,1.38a1,1,0,0,0,.77.8,7.93,7.93,0,0,1,2.64,1.1,1,1,0,0,0,1.11,0l1.16-.8.94.94-.8,1.16a1,1,0,0,0,0,1.11,7.93,7.93,0,0,1,1.1,2.64,1,1,0,0,0,.8.77l1.38.25Z" />
    <path d="M12,7a5,5,0,1,0,5,5A5,5,0,0,0,12,7Zm0,8a3,3,0,1,1,3-3A3,3,0,0,1,12,15Z" />
  </svg>
);

export const complaintIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
    <line x1="12" y1="8" x2="12" y2="12"></line>
    <line x1="12" y1="16" x2="12" y2="16"></line>
  </svg>
);

export const versionIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 512 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
  >
    <path
      d="M336 8H176C159.032 8.01906 142.764 14.768 130.766 26.7662C118.768 38.7644 112.019 55.032 112 72V440C112.019 456.968 118.768 473.236 130.766 485.234C142.764 497.232 159.032 503.981 176 504H336C352.968 503.981 369.236 497.232 381.234 485.234C393.232 473.236 399.981 456.968 400 440V72C399.981 55.032 393.232 38.7644 381.234 26.7662C369.236 14.768 352.968 8.01906 336 8ZM128 88H384V392H128V88ZM176 24H336C348.726 24.0127 360.928 29.0739 369.927 38.0729C378.926 47.0719 383.987 59.2735 384 72H128C128.013 59.2735 133.074 47.0719 142.073 38.0729C151.072 29.0739 163.274 24.0127 176 24ZM336 488H176C163.274 487.987 151.072 482.926 142.073 473.927C133.074 464.928 128.013 452.726 128 440V408H384V440C383.987 452.726 378.926 464.928 369.927 473.927C360.928 482.926 348.726 487.987 336 488Z"
      fill="#000"
    />
    <path
      d="M256 424C251.253 424 246.613 425.408 242.666 428.045C238.72 430.682 235.643 434.43 233.827 438.816C232.01 443.201 231.535 448.027 232.461 452.682C233.387 457.338 235.673 461.614 239.029 464.971C242.386 468.327 246.662 470.613 251.318 471.539C255.973 472.465 260.799 471.99 265.184 470.173C269.57 468.357 273.318 465.28 275.955 461.334C278.592 457.387 280 452.747 280 448C280 441.635 277.471 435.53 272.971 431.029C268.47 426.529 262.365 424 256 424ZM256 456C254.418 456 252.871 455.531 251.555 454.652C250.24 453.773 249.214 452.523 248.609 451.061C248.003 449.6 247.845 447.991 248.154 446.439C248.462 444.887 249.224 443.462 250.343 442.343C251.462 441.224 252.887 440.462 254.439 440.154C255.991 439.845 257.6 440.003 259.061 440.609C260.523 441.214 261.773 442.24 262.652 443.555C263.531 444.871 264 446.418 264 448C264 450.122 263.157 452.157 261.657 453.657C260.157 455.157 258.122 456 256 456ZM280 40H232C229.878 40 227.843 40.8429 226.343 42.3431C224.843 43.8434 224 45.8783 224 48C224 50.1217 224.843 52.1566 226.343 53.6569C227.843 55.1571 229.878 56 232 56H280C282.122 56 284.157 55.1571 285.657 53.6569C287.157 52.1566 288 50.1217 288 48C288 45.8783 287.157 43.8434 285.657 42.3431C284.157 40.8429 282.122 40 280 40Z"
      fill="#000"
    />
  </svg>
);

export const uploadIcon = (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="upload"
    className="svg-inline--fa fa-upload fa-w-16"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
    ></path>
  </svg>
);
