import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";

export const loginUser = createAsyncThunk(
  "loginUser",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post("/user/login", data);
      if (response.status === 201) {
        notify("Welcome Back", "success");
        return response.data;
      }
    } catch (error) {
      console.log("error", error.response);
      notify(error.response.data.message);
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

const initialUser = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;

const initialState = {
  user: initialUser,
  loader: false,
  error: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      localStorage.removeItem("user");
    },
  },

  extraReducers: (builder) => {
    // LOGIN USER
    builder.addCase(loginUser.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loader = false;
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload.data));
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});
export const { logout } = userSlice.actions;
export default userSlice.reducer;
