import React, { useRef, useState } from "react";
import CommonHeading from "../../component/Common/CommonComponent/CommonHeading";
import TripTableList from "../../component/Common/PageComponent/Trips/TripTableList";
import { useNavigate, useParams } from "react-router-dom";
import { backBtnArrow, reFresh } from "../../icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Modal } from "react-bootstrap";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTripsByVehId } from "../../store/slice/tripSlice";
import Loader from "../../component/Common/CommonComponent/Loader";
import { routes } from "../../constants";
import moment from "moment";
import ViewTripModal from "../../component/Common/Modals/ViewTripModal";

const TripsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const ref = useRef(false);
  const { page_number } = params;

  const [pageNumber, setPageNumber] = useState(page_number - 1);

  const [viewModal, setViewModal] = useState(false);

  const [tripId, setTripId] = useState("");
  const [prevTripId, setPrevTripId] = useState("");

  const trips = useSelector((state) => state.trip.allTrips);
  const loading = useSelector((state) => state.trip.loader);

  const [startDate, setStartDate] = useState("");
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [loading3, setLoading3] = useState(false);

  useEffect(() => {
    if (ref.current) {
      if (startDate && endDate) {
        setStart_date(moment(startDate).format("YYYY-MM-DD"));
        setEnd_date(moment(endDate).format("YYYY-MM-DD"));
      } else if (!startDate && !endDate) {
        setStart_date("");
        setEnd_date("");
      }
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (ref.current) {
      if (page_number !== 1) {
        navigate(`${routes.vehicleInv}/${params.id}/trips/page/1`);
      }
      dispatch(
        getTripsByVehId({
          id: params.id,
          start: (page_number - 1) * 10,
          limits: 10,
          start_date: start_date,
          end_date: end_date,
        })
      );
    }
  }, [start_date, end_date]);

  useEffect(() => {
    if (ref.current) {
      dispatch(
        getTripsByVehId({
          id: params.id,
          start: (page_number - 1) * 10,
          limits: 10,
          start_date: start_date,
          end_date: end_date,
        })
      );
    }
  }, [pageNumber]);

  useEffect(() => {
    dispatch(
      getTripsByVehId({
        id: params.id,
        start: (page_number - 1) * 10,
        limits: 10,
        start_date: start_date,
        end_date: end_date,
      })
    );
    ref.current = true;
  }, []);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const resetDateHandler = () => {
    setEndDate("");
    setStartDate("");
  };

  const showModal = (id) => {
    setTripId(id);
    setViewModal(true);
  };

  const closeModal = () => {
    setViewModal(false);
    setPrevTripId(tripId);
  };

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading="VEHICLE TRIPS" />
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="creat_edit_project_btn_row_flex">
              <div>
                <button
                  className="go_back_btn table_back_btn"
                  onClick={() => navigate(-1)}
                >
                  {backBtnArrow} Back
                </button>
              </div>
              <div className="common_all_filter_row">
                {/* <div className="common_filter_input_box first_filter_container">
                  <div className="common_select_box">
                    <Form.Select
                      onChange={handleServiceIdChange}
                      id="employee_name"
                      name="employee_name"
                      className="select_form"
                      defaultValue={"DEFAULT"}
                    >
                      <option value="DEFAULT" disabled>
                        Filter By Service
                      </option>
                      <option value="">ALL</option>
                      {services?.map((service, i) => {
                        return (
                          <option key={i} value={service.id}>
                            {service.service_name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>
                </div> */}
                <div className="common_filter_input_box first_filter_container">
                  <div className="common_select_box">
                    <Form.Select
                      // onChange={handleEmployeeIdChange}
                      id="employee_name"
                      name="employee_name"
                      className="select_form"
                      defaultValue={"DEFAULT"}
                    >
                      <option value="DEFAULT" disabled>
                        Filter By User
                      </option>
                      <option value="">ALL</option>
                      {/* {employees?.map((employee, i) => {
                        return (
                          <option key={i} value={employee.id}>
                            {employee.employee_name}
                          </option>
                        );
                      })} */}
                    </Form.Select>
                  </div>
                </div>
                <div className="common_filter_input_box">
                  <div className="project_date_range_input_container">
                    <div className="custom_date_picker">
                      <DatePicker
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Search By Date Range..."
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        maxDate={new Date()}
                      />
                    </div>
                    <span
                      className="filter_input_inside_btn"
                      role="button"
                      onClick={resetDateHandler}
                    >
                      {reFresh}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="custom_data_table_content">
              <TripTableList list={trips} showModal={showModal} />
            </div>

            {/* {totalCount > 0 && (
          <Pagination
            totalRecords={totalCount}
            onPageChange={onPageChange}
          />
        )} */}
          </>
        )}
      </div>
      <Modal
        className="modal_main_component modal_background_content"
        dialogClassName="custom-dialog"
        show={viewModal}
        onHide={closeModal}
        centered
      >
        <ViewTripModal
          closeModal={closeModal}
          tripId={tripId}
          prevTripId={prevTripId}
        />
      </Modal>
    </>
  );
};

export default TripsPage;
