import React from "react";
import { closeIcon } from "../../../icons";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTripDetailById } from "../../../store/slice/tripSlice";
import Loader from "../CommonComponent/Loader";
import moment from "moment";
import { defaultTimeZone } from "../../../helpers/commonFunction";

const ViewTripModal = ({ closeModal, tripId, prevTripId }) => {
  const dispatch = useDispatch();

  const tripDetail = useSelector((state) => state.trip.tripDetail);
  const loading = useSelector((state) => state.trip.extraLoader);

  useEffect(() => {
    if (prevTripId !== tripId) {
      dispatch(getTripDetailById({ id: tripId }));
    }
  }, [tripId]);

  return (
    <>
      <div className="modal_container">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="modal_heading">
              <h1 className="modal_main_heading">Trip Details</h1>

              <button className="modal_close_btn" onClick={() => closeModal()}>
                {closeIcon}
              </button>
            </div>
            <div className="modal_detail_container">
              <div className="modal_detail_container_grid only_detail_modal">
                <div className="modal_detail_content">
                  <h2 className="modal_detail_content_heading">Trip Date</h2>
                  <p>
                    {moment
                      .utc(tripDetail.created_at, "YYYYMMDD HH:mm:ss")
                      .clone()
                      .tz(defaultTimeZone)
                      .format("DD/MM/YYYY")}
                  </p>
                </div>
                <div className="modal_detail_content">
                  <h2 className="modal_detail_content_heading">Trip User</h2>
                  <p>{tripDetail.shredder_user_mapping?.user?.name}</p>
                </div>
                <div className="modal_detail_content">
                  <h2 className="modal_detail_content_heading">
                    Request Date & Time
                  </h2>
                  <p>
                    {/* {moment
                    .utc(reqDetail.request_date, "YYYYMMDD HH:mm:ss")
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A")} */}
                  </p>
                </div>
                <div className="modal_detail_content">
                  <h2 className="modal_detail_content_heading">User Name</h2>
                  <p>abcde</p>
                </div>
                <div className="modal_detail_content">
                  <h2 className="modal_detail_content_heading">User Email</h2>
                  <p>sdff</p>
                </div>
                <div className="modal_detail_content">
                  <h2 className="modal_detail_content_heading">
                    User Contact No.
                  </h2>
                  <p>3453455345</p>
                </div>

                <div className="modal_detail_content">
                  <h2 className="modal_detail_content_heading">UUID</h2>
                  <p>345345345 345 345 345 345345 5</p>
                </div>
                <div className="modal_detail_content span_2">
                  <h2 className="modal_detail_content_heading">Description</h2>
                  <p>
                    sfsf sf dsfs f dsf dsf dsfsd eswr ew5t5 tw tew 5rtqwtqew tew
                    tw tw t wt rwt w tw tw t e tw etywt ewt t rety
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ViewTripModal;
