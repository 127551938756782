import React from "react";
import { tableHeadItemsForSupportReq } from "../../CommonComponent/TableHeads";
import { editIcon, eyeIcon } from "../../../../icons";
import { defaultTimeZone } from "../../../../helpers/commonFunction";
import moment from "moment";

const RequestList = ({ list, showDetailModal, showUpdateReqModal }) => {
  return (
    <>
      {list?.length === 0 || !list ? (
        <p className="no_data_found">No data Found</p>
      ) : (
        <table className="custom_data_table">
          <thead className="custom_data_table_head">
            <tr>
              {tableHeadItemsForSupportReq.map((item, index) => {
                return (
                  <th
                    className="custom_data_table_heading text-center"
                    key={item.id}
                  >
                    {item.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="custom_data_table_body">
            {list?.map((item, i) => {
              return (
                <tr className="custom_data_table_row" key={item.id}>
                  <td className="custom_data_table_item table_item">
                    {item.user && item.user.name ? item.user.name : "-"}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.subject}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.status}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.shredder && item.shredder.shredder_name
                      ? item.shredder.shredder_name
                      : "-"}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.user && item.user.email ? item.user.email : "-"}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.user && item.user.phone ? item.user.phone : "-"}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {moment
                      .utc(item.request_date, "YYYYMMDD HH:mm:ss")
                      .clone()
                      .tz(defaultTimeZone)
                      .format("DD/MM/YYYY, hh:mm A")}
                  </td>

                  <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                    <div className="custom_data_table_view_edit_btn_item_row">
                      <button
                        className="custom_data_table_view_edit_item_btn"
                        onClick={(e) => showDetailModal(item.id)}
                      >
                        {eyeIcon}
                      </button>
                      <button
                        className="custom_data_table_view_edit_item_btn"
                        onClick={(e) => showUpdateReqModal(item)}
                      >
                        {editIcon}
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
};

export default RequestList;
