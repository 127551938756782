import React from "react";
import { Link } from "react-router-dom";
import { eyeIcon } from "../../../../icons";
import { tableHeadItemsForUser } from "../../CommonComponent/TableHeads";

const UserList = ({ list }) => {
  return (
    <>
      {list?.length === 0 || !list ? (
        <p className="no_data_found">No data Found</p>
      ) : (
        <table className="custom_data_table">
          <thead className="custom_data_table_head">
            <tr>
              {tableHeadItemsForUser.map((item, index) => {
                return (
                  <th
                    className="custom_data_table_heading text-center"
                    key={item.id}
                  >
                    {item.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="custom_data_table_body">
            {list?.map((item, i) => {
              return (
                <tr className="custom_data_table_row" key={item.id}>
                  <td className="custom_data_table_item table_item">
                    {item.role ? item.role.name : "-"}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.email ? item.email : "-"}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.phone ? item.phone : "-"}
                  </td>

                  <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                    <div className="custom_data_table_view_edit_btn_item_row">
                      <Link
                        className="custom_data_table_view_edit_item_btn"
                        aria-label="view"
                      >
                        {eyeIcon}
                      </Link>
                      {/* <Link
                        aria-label="edit"
                        className="custom_data_table_view_edit_item_btn"
                      >
                        {editIcon}
                      </Link> */}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
};

export default UserList;
