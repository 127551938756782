import React from "react";
import { closeIcon } from "../../../icons";

const DeleteVersionModal = ({ closeDeleteModal, deleteVehHandler }) => {
  return (
    <div className="modal_container">
      <div className="modal_heading centered_heading">
        <h1 className="modal_main_heading">Delete Version</h1>
      </div>
      <div className="modal_heading_2">
        <h1 className="modal_main_heading_with_desc">Are you sure?</h1>
        <p>you want to delete this version!</p>
      </div>
      <div className="modal_btn_row">
        <button
          onClick={() => closeDeleteModal()}
          className="modal_btn modal_cancel_btn"
        >
          Cancel
        </button>
        <button
          className="modal_btn modal_delete_btn"
          onClick={() => deleteVehHandler()}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default DeleteVersionModal;
