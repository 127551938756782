import React from "react";
import { useState } from "react";
// import Input from "../CommonComponent/Input";
import { Form } from "react-bootstrap";
import {
  getSupportRequests,
  updateSupportRequest,
} from "../../../store/slice/supportReqSlice";
import { useDispatch } from "react-redux";

const UpdateSupportReq = ({ closeModal, updateReqData, pageNumber }) => {
  const dispatch = useDispatch();

  const [reqStatus, setReqStatus] = useState(updateReqData.status);
  const [remarks, setRemarks] = useState(updateReqData.remark);

  const statusList = [
    {
      display_name: "Reopen",
      value: "Reopen",
      id: "reopen",
      disableValue: true,
    },
    {
      display_name: "Pending",
      value: "Pending",
      id: "pending",
      disableValue: true,
    },
    {
      display_name: "Resolved",
      value: "Resolved",
      id: "resolved",
      disableValue: true,
    },
    {
      display_name: "Closed",
      value: "Closed",
      id: "closed",
      disableValue: true,
    },
  ];

  const onStatusChange = (e) => {
    setReqStatus(e.target.value);
  };

  const onRemarksChange = (e) => {
    setRemarks(e.target.value);
  };

  const updateStatusHandler = () => {
    const data = {
      status: reqStatus,
    };

    if (remarks.trim() !== "") {
      data.remark = remarks;
    }

    dispatch(
      updateSupportRequest({
        data,
        id: updateReqData.id,
        toast: "Support Request has been updated!",
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            closeModal();
            setReqStatus("");
            setRemarks("");
            dispatch(
              getSupportRequests({ start: (pageNumber - 1) * 10, limits: 10 })
            );
          }
        },
      })
    );
  };

  return (
    <div className="modal_container">
      {/* {isLoading ? (
        <Loader />
      ) : ( */}
      <>
        <div className="modal_heading">
          <h1 className="modal_main_heading">Update Request</h1>
          {/* <button className="modal_close_btn" onClick={() => closeModal()}>
            {closeIcon}
          </button> */}
        </div>
        <div className="modal_detail_container">
          <div className="modal_detail_container_grid">
            <div className="modal_detail_content span_2 margin_top_container">
              <h2 className="modal_detail_content_heading margin_bottom_container">
                Update to...
              </h2>

              <div className="project_common_form_input_check_box_row">
                {statusList.map((status, i) => {
                  return (
                    <div className="project_common_form_check_box" key={i}>
                      <input
                        type="radio"
                        id={status.id}
                        name="status"
                        value={status.value}
                        checked={reqStatus === status.value}
                        onChange={onStatusChange}
                      />
                      <label htmlFor={status.id}>{status.display_name}</label>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* <div className="modal_detail_content span_2">
              <Input
                className="project_edit_feature_input"
                labelClassName="project_edit_feature_input_label"
                inputClassName="project_edit_feature_input_input"
                errorClassName="err_text"
                type="text"
                placeholder="Enter UUID Number"
                label="Remarks"
                id="remarks"
                name="remarks"
                // value={values.uuid}
                // onChange={handleInputChange}
                // error={errors.uuid || ""}
              />
            </div> */}
            <div className="project_edit_feature_input span_2">
              <h4 className="project_text_area_label">Remarks</h4>
              <Form.Control
                className="project_text_area"
                type="text"
                placeholder="Enter Remarks"
                as="textarea"
                rows={3}
                id="remarks"
                name="remarks"
                value={remarks}
                onChange={onRemarksChange}
              />
              {/* <span className="err_text">{errors?.description || ""}</span> */}
            </div>
          </div>
        </div>
      </>
      {/* )} */}

      <div className="modal_btn_row">
        <button
          onClick={() => closeModal()}
          className="modal_btn modal_cancel_btn"
        >
          Cancel
        </button>
        <button
          className="modal_btn modal_save_btn"
          onClick={() => updateStatusHandler()}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default UpdateSupportReq;
