import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { toast } from "react-toastify";
import { routes } from "../../constants";

export const getDashboardCount = createAsyncThunk(
  "getDashboardCount",
  async (data) => {
    try {
      const response = await Axios.get(`/dashboard`, authHeaders());
      return response.data.data;
      // return { data: response.data, count: response.headers["x-total-count"] };
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      console.log("error:", error.response);
      return error.response.data.message;
    }
  }
);

const initialState = {
  dashboardCount: {},
  loader: false,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    //DTV USER LIST
    builder.addCase(getDashboardCount.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getDashboardCount.fulfilled, (state, action) => {
      state.loader = false;
      state.dashboardCount = action.payload;
    });
    builder.addCase(getDashboardCount.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default dashboardSlice.reducer;
