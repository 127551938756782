import React from "react";
import { Link } from "react-router-dom";
import { routes } from "../../../../constants";
import { deleteIcon } from "../../../../icons";

const VehicleList = ({ list, showModal, showDeleteModal }) => {
  return (
    <>
      {list?.length === 0 || !list ? (
        <p className="no_data_found">No data Found</p>
      ) : (
        <div className="col_lg_4 d_grid gap_15">
          {list?.map((item, i) => (
            <div className={`dtv_vehicle_col`} key={item.id}>
              <div className="dtv_vehicle_col_img">
                <img
                  src={`${process.env.REACT_APP_FILE_BASE_URL}/${item.image}`}
                  alt="DTV Vehicles photo"
                />
                <span className="dtv_vehicle_col_label">{item.name}</span>
              </div>
              <div className="dtv_vehicle_col_text_content">
                <div className="dtv_vehicle_col_text_list">
                  <p className="dtv_vehicle_col_text">{item.description}</p>
                </div>
                <div className="dtv_vehicle_col_btn_row">
                  <button
                    className="comman_btn"
                    onClick={(e) => showModal(item)}
                    // to={`${routes.dtvVehiclesPage}/${item.id}`}
                  >
                    View
                  </button>
                  <Link
                    className="comman_btn"
                    to={`${routes.vehiclesPage}/update/${item.id}`}
                  >
                    Edit
                  </Link>
                  <button
                    aria-label="edit"
                    className="custom_data_tabl_delete_item_btn"
                    onClick={(e) => showDeleteModal(item.id)}
                  >
                    {deleteIcon}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default VehicleList;
