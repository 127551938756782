import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import CreateVehTypePage from "./CreateVehTypePage";
import { getShredderModelById } from "../../store/slice/shredderSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../component/Common/CommonComponent/Loader";

const EditVehTypePage = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const shredderDetail = useSelector((state) => state.shredder.shredderDetail);
  const loading = useSelector((state) => state.shredder.loader);
  const extraLoading = useSelector((state) => state.shredder.extraLoader);

  useEffect(() => {
    dispatch(getShredderModelById({ id: params.id }));
  }, [params]);

  return (
    <>
      {loading || extraLoading ? (
        <Loader />
      ) : (
        <CreateVehTypePage editDetail={shredderDetail} isEdit={true} />
      )}
    </>
  );
};

export default EditVehTypePage;
