import React from "react";
import CommonHeading from "../../component/Common/CommonComponent/CommonHeading";
import TripTableList from "../../component/Common/PageComponent/Trips/TripTableList";
import { useNavigate } from "react-router-dom";
import { backBtnArrow, reFresh } from "../../icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form } from "react-bootstrap";
import ErrorLogTableList from "../../component/Common/PageComponent/Logs/ErrorLogTableList";
import ActionLogTableList from "../../component/Common/PageComponent/Logs/ActionLogTableList";

const ActionLogsPage = () => {
  const navigate = useNavigate();

  const list = [
    {
      user_id: "33",
      action_msg: "navigation theme changes",
      action_type: "theme change",
      action_time: "2023-05-21T09:29:59.000Z",
    },
    {
      user_id: "19",
      action_msg: "fast charging",
      action_type: "bettery",
      action_time: "2023-07-17T09:29:59.000Z",
    },
    {
      user_id: "33",
      action_msg: "Speed Incresing",
      action_type: "Speed",
      action_time: "2023-03-03T09:29:59.000Z",
    },
  ];

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading="ACTIONS LOGS" />
        {/* {isLoading ? (
        <Loader />
      ) : ( */}
        <>
          <div className="creat_edit_project_btn_row_flex">
            <div>
              <button
                className="go_back_btn table_back_btn"
                onClick={() => navigate(-1)}
              >
                {backBtnArrow} Back
              </button>
            </div>
            <div className="common_all_filter_row">
              {/* <div className="common_filter_input_box first_filter_container">
                <div className="common_select_box">
                  <Form.Select
                    onChange={handleServiceIdChange}
                    id="employee_name"
                    name="employee_name"
                    className="select_form"
                    defaultValue={"DEFAULT"}
                  >
                    <option value="DEFAULT" disabled>
                      Filter By Service
                    </option>
                    <option value="">ALL</option>
                    {services?.map((service, i) => {
                      return (
                        <option key={i} value={service.id}>
                          {service.service_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </div>
              </div> */}
              <div className="common_filter_input_box first_filter_container">
                <div className="common_select_box">
                  <Form.Select
                    // onChange={handleEmployeeIdChange}
                    id="employee_name"
                    name="employee_name"
                    className="select_form"
                    defaultValue={"DEFAULT"}
                  >
                    <option value="DEFAULT" disabled>
                      Filter By User ID
                    </option>
                    <option value="">ALL</option>
                    {/* {employees?.map((employee, i) => {
                      return (
                        <option key={i} value={employee.id}>
                          {employee.employee_name}
                        </option>
                      );
                    })} */}
                  </Form.Select>
                </div>
              </div>
              <div className="common_filter_input_box">
                <div className="project_date_range_input_container">
                  <div className="custom_date_picker">
                    <DatePicker
                      // selected={startDate}
                      // onChange={onChange}
                      // startDate={startDate}
                      // endDate={endDate}
                      selectsRange
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Search By Date Range..."
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </div>
                  <span
                    className="filter_input_inside_btn"
                    role="button"
                    // onClick={resetDateHandler}
                  >
                    {reFresh}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="custom_data_table_content">
            <ActionLogTableList list={list} />
          </div>

          {/* {totalCount > 0 && (
        <Pagination
          totalRecords={totalCount}
          onPageChange={onPageChange}
        />
      )} */}
        </>
        {/* )} */}
      </div>
    </>
  );
};

export default ActionLogsPage;
