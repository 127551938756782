// Version form VALIDATIIONS
export const versionFormValidate = (
  fieldValues,
  temp,
  setValues,
  values,
  setErrors
) => {
  if ("version" in fieldValues) {
    temp.version = fieldValues.version.trim() ? "" : "Version is required!";
  }
  if ("hardware_batch" in fieldValues) {
    temp.hardware_batch = fieldValues.hardware_batch.trim()
      ? ""
      : "Hardware batch is required!";
  }
  if ("series" in fieldValues) {
    temp.series = fieldValues.series ? "" : "Series is required!!";
  }
  if ("description" in fieldValues) {
    temp.description = fieldValues.description.trim()
      ? ""
      : "Description is required!";
  }

  if ("bin_file" in fieldValues) {
    temp.bin_file = "";
    if (!fieldValues.bin_file) {
      temp.bin_file = "bin file is required!";
    }
    if (fieldValues.bin_file.size > 5000000) {
      temp.bin_file = "bin_file should not greater than 5 MB!";
      setValues({
        ...values,
        bin_file_preview: "",
        bin_file: "",
      });
    }
    if (
      fieldValues.bin_file.name &&
      !fieldValues.bin_file.name?.match(/.(bin)$/i)
    ) {
      temp.bin_file = "Please Upload valid file!";
      setValues({
        ...values,
        bin_file_preview: "",
        bin_file: "",
      });
    }
  }
  if ("app_configuration" in fieldValues) {
    temp.app_configuration = "";
    if (!fieldValues.app_configuration) {
      temp.app_configuration = "app_configuration file is required!";
    }
    if (fieldValues.app_configuration.size > 5000000) {
      temp.app_configuration =
        "app_configuration should not greater than 5 MB!";
      setValues({
        ...values,
        app_configuration_preview: "",
        app_configuration: "",
      });
    }
    if (
      fieldValues.app_configuration.name &&
      !fieldValues.app_configuration.name?.match(/.(xml)$/i)
    ) {
      temp.app_configuration = "Please Upload valid file!";
      setValues({
        ...values,
        app_configuration_preview: "",
        app_configuration: "",
      });
    }
  }
  if ("motor_configuration" in fieldValues) {
    temp.motor_configuration = "";
    if (!fieldValues.motor_configuration) {
      temp.motor_configuration = "motor_configuration file is required!";
    }
    if (fieldValues.motor_configuration.size > 5000000) {
      temp.motor_configuration =
        "motor_configuration should not greater than 5 MB!";
      setValues({
        ...values,
        motor_configuration_preview: "",
        motor_configuration: "",
      });
    }
    if (
      fieldValues.motor_configuration.name &&
      !fieldValues.motor_configuration.name?.match(/.(xml)$/i)
    ) {
      temp.motor_configuration = "Please Upload valid file!";
      setValues({
        ...values,
        motor_configuration_preview: "",
        motor_configuration: "",
      });
    }
  }

  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};
