import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CommonHeading from "../../component/Common/CommonComponent/CommonHeading";
import VehicleInvList from "../../component/Common/PageComponent/Inventory/VehicleInvList";
import { routes } from "../../constants";
import { getInvVehicles } from "../../store/slice/inventorySlice";
import Pagination from "../../component/Pagination/Pagination";
import Loader from "../../component/Common/CommonComponent/Loader";

const VehicleInvPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const ref = useRef(false);
  const { page_number } = params;

  const [pageNumber, setPageNumber] = useState(page_number - 1);

  const invVehList = useSelector((state) => state.inventory.invVehiclesList);
  const totalCount = useSelector(
    (state) => state.inventory.totalInvVehicleCount
  );
  const isLoading = useSelector((state) => state.inventory.loader);

  useEffect(() => {
    if (ref.current) {
      dispatch(getInvVehicles({ start: (page_number - 1) * 10, limits: 10 }));
    }
  }, [pageNumber]);

  useEffect(() => {
    dispatch(getInvVehicles({ start: (page_number - 1) * 10, limits: 10 }));
    ref.current = true;
  }, []);

  const onPageChange = (page_number) => {
    if (ref.current) {
      setPageNumber(page_number);
    }
  };

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading="VEHICLE INVENTORY" />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="creat_edit_project_btn_row_flex">
              <div>
                <Link
                  className="comman_btn ml-auto"
                  to={`${routes.vehicleInv}/add`}
                >
                  Add Vehicle
                </Link>
              </div>
              {/* {userPageFilter({ onChangingSearchField, searchData })} */}
            </div>

            <div className="custom_data_table_content">
              <VehicleInvList list={invVehList} />
            </div>

            {totalCount > 0 && (
              <Pagination
                totalRecords={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default VehicleInvPage;
