import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slice/userSlice";
import shredderSlice from "./slice/shredderSlice";
import inventorySlice from "./slice/inventorySlice";
import dtvUserSlice from "./slice/dtvUserSlice";
import tripSlice from "./slice/tripSlice";
import dashboardSlice from "./slice/dashboardSlice";
import supportReqSlice from "./slice/supportReqSlice";
import versionSlice from "./slice/versionSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    shredder: shredderSlice,
    inventory: inventorySlice,
    dtvUser: dtvUserSlice,
    trip: tripSlice,
    dashboard: dashboardSlice,
    supportReq: supportReqSlice,
    version: versionSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
