// Coupon TABLE HEAD LIST
export const tableHeadItemsForVehicles = [
  {
    id: 1,
    name: "SHREDDER NAME",
  },
  {
    id: 2,
    name: "MODEL NAME",
  },
  {
    id: 3,
    name: "VIN",
  },
  {
    id: 4,
    name: "UUID",
  },
  {
    id: 5,
    name: "Registration Status",
  },
  {
    id: 6,
    name: "MANUFECTURE DATE",
  },
  {
    id: 7,
    name: "PURCHASED DATE",
  },
  {
    id: 8,
    name: "Actions",
  },
];
//

// Error TABLE HEAD LIST
export const tableHeadItemsForErrorLog = [
  {
    id: 1,
    name: "Error Code",
  },
  {
    id: 2,
    name: "Error Message",
  },
  {
    id: 3,
    name: "Error Date",
  },
  {
    id: 4,
    name: "Error Type",
  },
  {
    id: 5,
    name: "Actions",
  },
];

//
// Action TABLE HEAD LIST
export const tableHeadItemsForActionLog = [
  {
    id: 1,
    name: "User ID",
  },
  {
    id: 2,
    name: "Action Message",
  },
  {
    id: 3,
    name: "Action Type",
  },
  {
    id: 4,
    name: "Action Date",
  },

  {
    id: 5,
    name: "Actions",
  },
];
//

// TRIP TABLE HEAD LIST
export const tableHeadItemsForTrip = [
  {
    id: 1,
    name: "Trip Date",
  },
  {
    id: 2,
    name: "Trip User",
  },
  {
    id: 3,
    name: "Trip Duration",
  },
  {
    id: 4,
    name: "Trip Distance",
  },
  {
    id: 5,
    name: "Start Geo Location",
  },
  {
    id: 6,
    name: "End Geo Location",
  },
  {
    id: 7,
    name: "Actions",
  },
];
//

// USER TABLE HEAD LIST
export const tableHeadItemsForUser = [
  {
    id: 1,
    name: "Name",
  },
  {
    id: 2,
    name: "EMAIL ID",
  },
  {
    id: 3,
    name: "CONTACT NO",
  },
  {
    id: 4,
    name: "ACTIONS",
  },
];
//

// SUPPORT TABLE HEAD LIST
export const tableHeadItemsForSupportReq = [
  {
    id: 1,
    name: "User Name",
  },
  {
    id: 2,
    name: "Subject",
  },
  {
    id: 4,
    name: "Status",
  },
  {
    id: 5,
    name: "Shredder Model",
  },
  {
    id: 6,
    name: "Email",
  },
  {
    id: 7,
    name: "CONTACT NO",
  },
  {
    id: 8,
    name: "Request Date",
  },
  {
    id: 9,
    name: "ACTIONS",
  },
];
//

// Version TABLE HEAD LIST
export const tableHeadItemsForVersion = [
  {
    id: 1,
    name: "Version",
  },
  {
    id: 2,
    name: "Hardware",
  },
  {
    id: 3,
    name: "Description",
  },
  {
    id: 4,
    name: "Series",
  },
  {
    id: 5,
    name: "Bin",
  },
  {
    id: 6,
    name: "App Configuration",
  },
  {
    id: 7,
    name: "Motor Configuration",
  },
  {
    id: 9,
    name: "Created_AT",
  },
  {
    id: 10,
    name: "Action",
  },
];
