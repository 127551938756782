import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import CommonHeading from "../../component/Common/CommonComponent/CommonHeading";
import { backBtnArrow } from "../../icons";
import VehInvForm from "../../component/Common/PageComponent/Inventory/VehInvForm";
import { useForm } from "../../hooks/useForm";
import {
  uuidValidate,
  vehInvFormValidate,
} from "../../component/Common/PageComponent/Inventory/VehicleInvCommon";
import { getShredderModels } from "../../store/slice/shredderSlice";
import Loader from "../../component/Common/CommonComponent/Loader";
import {
  addInvVehicle,
  updateInvVehicle,
} from "../../store/slice/inventorySlice";

const AddVehicleInvPage = ({ editDetail, isEdit }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const shredderList = useSelector((state) => state.shredder.shreddersList);
  const getShredderLoading = useSelector((state) => state.shredder.loader);

  const initialFValues = {
    uuid:
      editDetail && editDetail.shredder_uuid ? editDetail.shredder_uuid : "",
    vin_number:
      editDetail && editDetail.vin_number ? editDetail.vin_number : "",
    shredder_name:
      editDetail && editDetail.shredder_name ? editDetail.shredder_name : "",
    manf_date:
      editDetail && editDetail.manufacturing_date
        ? new Date(editDetail.manufacturing_date)
        : "",
    shredder_model_id:
      editDetail && editDetail.shredder_model_id
        ? {
            value: editDetail.shredder_model_id,
            label: editDetail.shredder_model.name,
          }
        : "",
    firmware: editDetail && editDetail.firmware ? editDetail.firmware : "",
    hardware: editDetail && editDetail?.batch ? editDetail?.batch : "",
  };

  useEffect(() => {
    dispatch(getShredderModels({ start: 0, limits: 100000 }));
  }, []);

  // validation
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    return vehInvFormValidate(fieldValues, temp, setValues, values, setErrors);
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const addVehicleHandler = () => {
    if (
      validate()
      // && uuidValidate(values, errors, setErrors)
    ) {
      const data = {
        shredder_model_id: values.shredder_model_id.value,
        shredder_uuid: values.uuid,
        shredder_name: values.shredder_name,
        // manufacturing_date: moment
        //   .utc(moment(values.manf_date).utc())
        //   .format("YYYY-MM-DD"),
        manufacturing_date: moment(values.manf_date).format("DD-MM-YYYY"),
        vin_number: values.vin_number,
        firmware: values.firmware,
        batch: values.hardware,
      };
      if (isEdit) {
        dispatch(
          updateInvVehicle({
            data,
            id: editDetail.id,
            toast: "Shredder Updated!",
            navigate,
          })
        );
      } else {
        dispatch(
          addInvVehicle({ data, toast: "New Shredder added!", navigate })
        );
      }
    }
  };

  return (
    <div className="project_edit_main_content">
      <CommonHeading heading={isEdit ? "EDIT VEHICLE" : "ADD VEHICLE"} />
      {getShredderLoading ? (
        <Loader />
      ) : (
        <>
          <div className="project_edit_main_link">
            <div className="comman_center_btn_container">
              <button className="go_back_btn" onClick={() => navigate(-1)}>
                {backBtnArrow} Back
              </button>
            </div>
          </div>
          <VehInvForm
            values={values}
            handleInputChange={handleInputChange}
            errors={errors}
            setValues={setValues}
            setErrors={setErrors}
            validate={validate}
            addVehicleHandler={addVehicleHandler}
            shredderList={shredderList}
            isEdit={isEdit}
            //   variants={variants}
            //   setVariants={setVariants}
            //   setVariantErr={setVariantErr}
            //   variantErr={variantErr}
          />
        </>
      )}
    </div>
  );
};

export default AddVehicleInvPage;
