// Add Vehicle VALIDATIIONS
export const vehInvFormValidate = (
  fieldValues,
  temp,
  setValues,
  values,
  setErrors
) => {
  if ("uuid" in fieldValues) {
    temp.uuid = fieldValues.uuid.trim() ? "" : "UUID Number is required!";
  }
  if ("vin_number" in fieldValues) {
    temp.vin_number = fieldValues.vin_number.trim()
      ? ""
      : "Vehicle Identification Number is required!";
  }
  if ("shredder_model_id" in fieldValues) {
    temp.shredder_model_id = fieldValues.shredder_model_id
      ? ""
      : "Please Select Shredder Model!";
  }
  if ("shredder_name" in fieldValues) {
    temp.shredder_name = fieldValues.shredder_name.trim()
      ? ""
      : "Shredder Name is required!";
  }
  if ("firmware" in fieldValues) {
    temp.firmware = fieldValues.firmware.trim()
      ? ""
      : "Shredder Firmware is required!";
  }
  if ("hardware" in fieldValues) {
    temp.hardware = fieldValues.hardware.trim()
      ? ""
      : "Shredder hardware is required!";
  }
  if ("manf_date" in fieldValues) {
    temp.manf_date = !fieldValues.manf_date
      ? "Manufacture Date is required!"
      : "";
  }

  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};
//

export const uuidValidate = (values, errors, setErrors) => {
  var uuidFormat =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  var uuidReg = new RegExp(uuidFormat);
  // var vinFormat = /^[A-HJ-NPR-Z\\d]{8}[\\dX][A-HJ-NPR-Z\\d]{2}\\d{6}$/i;
  // var vinReg = new RegExp(vinFormat);
  if (
    values.uuid.match(uuidReg)
    //  && values.vin_number.match(vinReg)
  ) {
    return true;
  } else {
    if (!values.uuid.match(uuidReg)) {
      setErrors({
        ...errors,
        uuid: "Please Enter Valid UUID number!",
      });
    }
    // if (!values.vin_number.match(vinReg)) {
    //   setErrors({
    //     ...errors,
    //     vin_number: "Please Enter Valid VIN number!",
    //   });
    // }
    return false;
  }
};
