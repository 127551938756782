import React, { useEffect } from "react";
import CommonHeading from "../../component/Common/CommonComponent/CommonHeading";
import { Link, useNavigate, useParams } from "react-router-dom";
import { backBtnArrow } from "../../icons";
import VehicleInvDetails from "../../component/Common/PageComponent/Inventory/VehicleInvDetails";
import { routes } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { getInvVehicleById } from "../../store/slice/inventorySlice";
import Loader from "../../component/Common/CommonComponent/Loader";
import { getAddress } from "../../store/slice/tripSlice";

const ViewVehicleInvPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const invVehDetail = useSelector((state) => state.inventory.invVehicleDetail);
  const loading = useSelector((state) => state.inventory.loader);

  useEffect(() => {
    dispatch(getInvVehicleById({ id: params.id }));
  }, [params]);

  return (
    <div className="project_edit_main_content">
      <CommonHeading heading={"VEHICLE INVENTORY"} />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="project_edit_main_link">
            <div className="comman_center_btn_container">
              <button className="go_back_btn" onClick={() => navigate(-1)}>
                {backBtnArrow} Back
              </button>
              <div className="project_multiple_tab">
                <Link
                  className="comman_btn extra_btn_row"
                  to={`${routes.vehicleInv}/${params.id}/trips/page/1`}
                >
                  Trips
                </Link>
                <Link
                  className="comman_btn extra_btn_row"
                  to={`${routes.vehicleInv}/${params.id}/error-logs`}
                >
                  Error Logs
                </Link>
                <Link
                  className="comman_btn extra_btn_row"
                  to={`${routes.vehicleInv}/${params.id}/action-logs`}
                >
                  Action Logs
                </Link>
              </div>
            </div>
          </div>
          <VehicleInvDetails invVehDetail={invVehDetail} />
        </>
      )}
    </div>
  );
};

export default ViewVehicleInvPage;
