import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CommonHeading from "../../component/Common/CommonComponent/CommonHeading";
import VehicleList from "../../component/Common/PageComponent/Vehicles/VehicleList";
import { routes } from "../../constants";
import { Link, useParams } from "react-router-dom";
import {
  deleteShredderModel,
  getShredderModels,
} from "../../store/slice/shredderSlice";
import Loader from "../../component/Common/CommonComponent/Loader";
import Pagination from "../../component/Pagination/Pagination";
import ViewVehicleModal from "../../component/Common/Modals/ViewVehicleModal";
import DeleteVehModal from "../../component/Common/Modals/DeleteVehModal";
import { toast } from "react-toastify";

const VehiclesPage = () => {
  const dispatch = useDispatch();
  const ref = useRef(false);
  const { page_number } = useParams();
  const [pageNumber, setPageNumber] = useState(page_number - 1);

  const shredderList = useSelector((state) => state.shredder.shreddersList);
  const totalCount = useSelector((state) => state.shredder.totalShredderCount);
  const isLoading = useSelector((state) => state.shredder.loader);

  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showDeteteModal, setShowDeteteModal] = useState(false);
  const [deteteModalId, setDeteteModalId] = useState("");
  const [vehModalId, setVehModalId] = useState("");
  const [prevVehModalId, setPrevVehModalId] = useState("");

  useEffect(() => {
    if (ref.current) {
      dispatch(getShredderModels({ start: (page_number - 1) * 8, limits: 8 }));
    }
  }, [pageNumber]);

  useEffect(() => {
    dispatch(getShredderModels({ start: (page_number - 1) * 8, limits: 8 }));
    ref.current = true;
  }, []);

  const showModal = (item) => {
    setShowDetailModal(true);
    setVehModalId(item.id);
  };
  const closeModal = () => {
    setPrevVehModalId(vehModalId);
    setShowDetailModal(false);
  };

  const showDeleteModal = (id) => {
    setShowDeteteModal(true);
    setDeteteModalId(id);
  };

  const closeDeleteModal = () => {
    setShowDeteteModal(false);
    setDeteteModalId("");
  };

  const onPageChange = (page_number) => {
    if (ref.current) {
      setPageNumber(page_number);
    }
  };

  const deleteVehHandler = () => {
    dispatch(
      deleteShredderModel({
        id: deteteModalId,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            toast.success("Shredder Model has been deleted!", {
              autoClose: 3000,
            });
            dispatch(
              getShredderModels({ start: (page_number - 1) * 8, limits: 8 })
            );
            setShowDeteteModal(false);
          }
        },
      })
    );
  };

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading="DTV VEHICLES" />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="creat_edit_project_btn_row_flex">
              <div>
                <Link
                  className="comman_btn ml-auto"
                  // onClick={(e) => addUserPageHandler()}
                  to={routes.createVehTypePage}
                >
                  Add Model
                </Link>
              </div>
              {/* {userPageFilter({ onChangingSearchField, searchData })} */}
            </div>

            <VehicleList
              list={shredderList}
              showModal={showModal}
              showDeleteModal={showDeleteModal}
            />
            {totalCount > 0 && (
              <Pagination
                totalRecords={totalCount}
                onPageChange={onPageChange}
                customLimits={8}
              />
            )}
          </>
        )}
      </div>
      <Modal
        className="modal_main_component modal_background_content"
        dialogClassName="custom-dialog"
        show={showDetailModal}
        onHide={closeModal}
        centered
      >
        <ViewVehicleModal
          closeModal={closeModal}
          vehModalId={vehModalId}
          prevVehModalId={prevVehModalId}
        />
      </Modal>
      <Modal
        className="modal_main_component modal_background_content"
        // dialogClassName="custom-dialog"
        show={showDeteteModal}
        onHide={closeDeleteModal}
        centered
      >
        <DeleteVehModal
          deteteModalId={deteteModalId}
          closeDeleteModal={closeDeleteModal}
          deleteVehHandler={deleteVehHandler}
        />
      </Modal>
    </>
  );
};

export default VehiclesPage;
