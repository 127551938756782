import React, { useEffect, useState } from "react";
import Input from "../../CommonComponent/Input";
import DatePicker from "react-datepicker";
import Button from "../../CommonComponent/Button";
import moment from "moment";
import Select from "react-select";

const VehInvForm = ({
  values,
  handleInputChange,
  errors,
  setValues,
  setErrors,
  addVehicleHandler,
  shredderList,
  isEdit,
}) => {
  // get Options for select user Name
  const modelOptions = shredderList.map((shredder, i) => {
    let options = {
      value: shredder.id,
      label: shredder.name,
    };
    return options;
  });

  const onModelIdchange = (e) => {
    setValues({
      ...values,
      shredder_model_id: e,
    });
    setErrors({
      ...errors,
      shredder_model_id: "",
    });
  };

  // const handleInputChangeForUUID = (event) => {
  //   const inputValue = event.target.value;
  //   // let formattedValue = inputValue.replace(/-/g, ""); // Remove existing hyphens

  //   // if (formattedValue.length > 8) {
  //   //   formattedValue =
  //   //     formattedValue.slice(0, 8) + "-" + formattedValue.slice(8);
  //   // }
  //   // if (formattedValue.length > 13) {
  //   //   formattedValue =
  //   //     formattedValue.slice(0, 13) + "-" + formattedValue.slice(13);
  //   // }
  //   // if (formattedValue.length > 18) {
  //   //   formattedValue =
  //   //     formattedValue.slice(0, 18) + "-" + formattedValue.slice(18);
  //   // }
  //   // if (formattedValue.length > 23) {
  //   //   formattedValue =
  //   //     formattedValue.slice(0, 23) + "-" + formattedValue.slice(23);
  //   // }

  //   setValues({ ...values, uuid: inputValue });
  //   setErrors({ ...errors, uuid: "" });
  // };

  return (
    <>
      <div className="create_from_row">
        <Input
          className="project_edit_feature_input"
          errorClassName="err_text"
          type="text"
          placeholder="Enter UUID Number"
          label="UUID"
          id="uuid"
          name="uuid"
          value={values.uuid}
          onChange={handleInputChange}
          error={errors.uuid || ""}
        />
        <Input
          className="project_edit_feature_input"
          errorClassName="err_text"
          type="text"
          placeholder="Enter VI Number"
          label="VIN (Vehicle Identification Number)"
          id="vin_number"
          name="vin_number"
          value={values.vin_number}
          onChange={handleInputChange}
          error={errors.vin_number || ""}
        />
        <div className="project_edit_feature_input">
          <h4 className="project_text_area_label">Select Vehicle Model</h4>

          <Select
            classNamePrefix="project_common_select_input"
            errorClassName="err_text"
            type="text"
            placeholder="Search..."
            label="Select Vehicle"
            id="shredder_model_id"
            name="shredder_model_id"
            // isClearable={true}
            isSearchable={true}
            onChange={onModelIdchange}
            value={values.shredder_model_id}
            //   error={errors?.salon_name || ""}
            options={modelOptions}
          />
          {errors.shredder_model_id && (
            <span className="err_text">{errors.shredder_model_id}</span>
          )}
        </div>
        <Input
          className="project_edit_feature_input"
          errorClassName="err_text"
          type="text"
          placeholder="Enter Name"
          label="Shredder Name"
          id="shredder_name"
          name="shredder_name"
          value={values.shredder_name}
          onChange={handleInputChange}
          error={errors.shredder_name || ""}
        />
        <Input
          className="project_edit_feature_input"
          errorClassName="err_text"
          type="text"
          placeholder="Enter Firmware"
          label="Firmware"
          id="firmware"
          name="firmware"
          value={values.firmware}
          onChange={handleInputChange}
          error={errors.firmware || ""}
        />

        <div className="project_edit_feature_input">
          <h4 className="project_text_area_label">Manufacture Date</h4>
          <div className="custom_date_picker">
            <DatePicker
              id="manf_date"
              className="zu_common_form_input"
              placeholderText="MM/DD/YYYY"
              dateFormat="dd/MM/yyyy"
              name="manf_date"
              selected={values.manf_date}
              dateFormatCalendar={"MMM yyyy"}
              onChange={(date) => {
                setValues({
                  ...values,
                  manf_date: date,
                });
                setErrors({
                  ...errors,
                  manf_date: "",
                });
              }}
              maxDate={moment().toDate()}
            />
          </div>
          {errors.manf_date && (
            <span className="err_text">{errors.manf_date}</span>
          )}
        </div>

        <Input
          className="project_edit_feature_input"
          errorClassName="err_text"
          type="text"
          placeholder="Enter hardware batch"
          label="Hardware"
          id="hardware"
          name="hardware"
          value={values.hardware}
          onChange={handleInputChange}
          error={errors.hardware || ""}
        />
      </div>
      <Button
        className="project_submit_bottom_btn center_back_btn"
        buttonClassName="comman_btn"
        onClick={addVehicleHandler}
        text={isEdit ? "Save" : "Add"}
      />
    </>
  );
};

export default VehInvForm;
