import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { toast } from "react-toastify";
import { routes } from "../../constants";

export const addInvVehicle = createAsyncThunk("addInvVehicle", async (data) => {
  try {
    const response = await Axios.post(
      `/shredder/admin-shredder-add`,
      data.data,
      authHeaders()
    );
    if (response.status === 201) {
      toast.success(data.toast, {
        autoClose: 3000,
      });
      data.navigate(`${routes.vehicleInv}/page/1`);
      return response.data;
    }
  } catch (error) {
    toast.error(error.response.data.message, {
      autoClose: 3000,
    });
    console.log("error:", error.response);
  }
});

export const updateInvVehicle = createAsyncThunk(
  "updateInvVehicle",
  async (data) => {
    try {
      const response = await Axios.put(
        `/shredder/admin-shredder-edit/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(`${routes.vehicleInv}/page/1`);
        return response.data;
      }
    } catch (error) {}
  }
);

export const getInvVehicles = createAsyncThunk(
  "getInvVehicles",
  async (data) => {
    try {
      const response = await Axios.get(
        `/shredder/admin-shredder-list?start=${data.start || ""}&limits=${
          data.limits || ""
        }`,
        authHeaders()
      );
      return response.data.data;
      // return { data: response.data, count: response.headers["x-total-count"] };
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      console.log("error:", error.response);
      return error.response.data.message;
    }
  }
);

export const getInvVehicleById = createAsyncThunk(
  "getInvVehicleById",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/shredder/admin-shredder-get/${data.id}`,
        authHeaders()
      );
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

const initialState = {
  invVehiclesList: [],
  totalInvVehicleCount: 0,
  invVehicleDetail: {},
  loader: false,
  createComponent: "",
  updateComponent: "",
};

const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    //ADD SHREDDER
    builder.addCase(addInvVehicle.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(addInvVehicle.fulfilled, (state, action) => {
      state.loader = false;
      state.createComponent = action.payload;
    });
    builder.addCase(addInvVehicle.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //UPDATE SHREDDER
    builder.addCase(updateInvVehicle.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateInvVehicle.fulfilled, (state, action) => {
      state.loader = false;
      state.updateComponent = action.payload;
    });
    builder.addCase(updateInvVehicle.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //SHREDDER MODELS LIST
    builder.addCase(getInvVehicles.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getInvVehicles.fulfilled, (state, action) => {
      state.loader = false;
      state.invVehiclesList = action.payload.rows;
      state.totalInvVehicleCount = action.payload.count;
    });
    builder.addCase(getInvVehicles.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //GET SHREDDER MODEL
    builder.addCase(getInvVehicleById.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getInvVehicleById.fulfilled, (state, action) => {
      state.loader = false;
      state.invVehicleDetail = action.payload;
    });
    builder.addCase(getInvVehicleById.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default inventorySlice.reducer;
