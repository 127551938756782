import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CommonHeading from "../../component/Common/CommonComponent/CommonHeading";
import UserList from "../../component/Common/PageComponent/Users/UserList";
import { getDtvUsers } from "../../store/slice/dtvUserSlice";
import Loader from "../../component/Common/CommonComponent/Loader";
import Pagination from "../../component/Pagination/Pagination";

const UsersPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const ref = useRef(false);
  const { page_number } = params;

  const [pageNumber, setPageNumber] = useState(page_number - 1);

  const dtvUserList = useSelector((state) => state.dtvUser.dtvUserList);
  const totalCount = useSelector((state) => state.dtvUser.totalUserCount);
  const isLoading = useSelector((state) => state.dtvUser.loader);

  useEffect(() => {
    if (ref.current) {
      dispatch(getDtvUsers({ start: (page_number - 1) * 10, limits: 10 }));
    }
  }, [pageNumber]);

  useEffect(() => {
    dispatch(getDtvUsers({ start: (page_number - 1) * 10, limits: 10 }));
    ref.current = true;
  }, []);

  const onPageChange = (page_number) => {
    if (ref.current) {
      setPageNumber(page_number);
    }
  };

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading="USERS" />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="custom_data_table_content">
              <UserList list={dtvUserList} />
            </div>

            {totalCount > 0 && (
              <Pagination
                totalRecords={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default UsersPage;
