import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { toast } from "react-toastify";
import { routes } from "../../constants";

export const getTripsByVehId = createAsyncThunk(
  "getTripsByVehId",
  async (data) => {
    try {
      const response = await Axios.get(
        `/trip/trip-data-shredder/${data.id}?start=${data.start || ""}&limits=${
          data.limits || 10
        }&start_date=${data.start_date || ""}&end_date=${data.end_date || ""}`,
        authHeaders()
      );
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      console.log("error:", error.response);
      return error.response.data.message;
    }
  }
);

export const getTripDetailById = createAsyncThunk(
  "getTripDetailById",
  async (data) => {
    try {
      const response = await Axios.get(
        `/trip/trip-list/${data.id}`,
        authHeaders()
      );
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      console.log("error:", error.response);
      return error.response.data.message;
    }
  }
);

const initialState = {
  allTrips: [],
  totalTripCount: 0,
  tripDetail: {},
  loader: false,
  extraLoader: false,
};

const tripSlice = createSlice({
  name: "trip",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    //SHREDDER TRIP LIST
    builder.addCase(getTripsByVehId.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getTripsByVehId.fulfilled, (state, action) => {
      state.loader = false;
      state.allTrips = action.payload.rows;
      state.totalTripCount = action.payload.count;
    });
    builder.addCase(getTripsByVehId.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //SHREDDER TRIP LIST
    builder.addCase(getTripDetailById.pending, (state) => {
      state.extraLoader = true;
    });
    builder.addCase(getTripDetailById.fulfilled, (state, action) => {
      state.extraLoader = false;
      state.tripDetail = action.payload;
    });
    builder.addCase(getTripDetailById.rejected, (state, action) => {
      state.extraLoader = false;
      state.error = action.payload;
    });
  },
});

export default tripSlice.reducer;
