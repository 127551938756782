import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "./axios";

export const fileUpload = createAsyncThunk(
  "fileUpload",
  async (formData, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/file/upload`,
        formData,
        authHeaders()
      );
      return response.data.key;
    } catch (error) {
      console.log("error", error);
    }
  }
);
