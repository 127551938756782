// USER FORM VALIDATIIONS
export const vehTypeFormValidate = (
  fieldValues,
  temp,
  setValues,
  values,
  setErrors
) => {
  if ("image" in fieldValues) {
    temp.image = "";
    if (!fieldValues.image) {
      temp.image = "Shredder image is required!";
    }
    if (fieldValues.image.size > 5000000) {
      temp.image = "Image should not greater than 5 MB!";
      setValues({
        ...values,
        image_preview: "",
        image: "",
      });
    }
    if (
      fieldValues.image.name &&
      !fieldValues.image.name?.match(/.(jpg|jpeg|png|gif)$/i)
    ) {
      temp.image = "Please Upload valid image!";
      setValues({
        ...values,
        image_preview: "",
        image: "",
      });
    }
  }

  if ("name" in fieldValues) {
    temp.name = fieldValues.name.trim() ? "" : "Shredder Name is required!";
  }
  if ("description" in fieldValues) {
    temp.description = fieldValues.description.trim()
      ? ""
      : "Shredder Description is required!";
  }

  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};
//
