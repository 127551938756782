import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { toast } from "react-toastify";
import { routes } from "../../constants";

export const addShredderModel = createAsyncThunk(
  "addShredderModel",
  async (data) => {
    try {
      const response = await Axios.post(
        `/shredder-model`,
        data.data,
        authHeaders()
      );
      if (response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(`${routes.vehiclesPage}/page/1`);
        return response.data;
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      console.log("error:", error.response);
    }
  }
);

export const updateShredderModel = createAsyncThunk(
  "updateShredderModel",
  async (data) => {
    try {
      const response = await Axios.put(
        `/shredder-model/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(`${routes.vehiclesPage}/page/1`);
        return response.data;
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      console.log("error:", error.response);
    }
  }
);
export const deleteShredderModel = createAsyncThunk(
  "deleteShredderModel",
  async (data) => {
    try {
      const response = await Axios.delete(
        `/shredder-model/${data.id}`,
        authHeaders()
      );
      if (response.status === 201 && data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      console.log("error:", error.response);
    }
  }
);

export const getShredderModels = createAsyncThunk(
  "getShredderModels",
  async (data) => {
    try {
      const response = await Axios.get(
        `/shredder-model?start=${data.start || 0}&limits=${data.limits || 8}`,
        authHeaders()
      );
      return response.data.data;
      // return { data: response.data, count: response.headers["x-total-count"] };
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      console.log("error:", error.response);
      return error.response.data.message;
    }
  }
);

export const getShredderModelById = createAsyncThunk(
  "getShredderModelById",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/shredder-model/${data.id}`,
        authHeaders()
      );
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

const initialState = {
  shreddersList: [],
  totalShredderCount: 0,
  shredderDetail: {},
  loader: false,
  extraLoader: false,
  createComponent: "",
  updateComponent: "",
};

const shredderSlice = createSlice({
  name: "shredderModels",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    //ADD SHREDDER
    builder.addCase(addShredderModel.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(addShredderModel.fulfilled, (state, action) => {
      state.loader = false;
      state.createComponent = action.payload;
    });
    builder.addCase(addShredderModel.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //UPDATE SHREDDER
    builder.addCase(updateShredderModel.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateShredderModel.fulfilled, (state, action) => {
      state.loader = false;
      state.updateComponent = action.payload;
    });
    builder.addCase(updateShredderModel.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //SHREDDER MODELS LIST
    builder.addCase(getShredderModels.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getShredderModels.fulfilled, (state, action) => {
      state.loader = false;
      state.shreddersList = action.payload.rows;
      state.totalShredderCount = action.payload.count;
    });
    builder.addCase(getShredderModels.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //GET SHREDDER MODEL
    builder.addCase(getShredderModelById.pending, (state) => {
      state.extraLoader = true;
    });
    builder.addCase(getShredderModelById.fulfilled, (state, action) => {
      state.extraLoader = false;
      state.shredderDetail = action.payload;
    });
    builder.addCase(getShredderModelById.rejected, (state, action) => {
      state.extraLoader = false;
      state.error = action.payload;
    });
  },
});

export default shredderSlice.reducer;
