import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { toast } from "react-toastify";

export const getSupportRequests = createAsyncThunk(
  "getSupportRequests",
  async (data) => {
    try {
      const response = await Axios.get(
        `/support-request?start=${data.start || 0}&limits=${
          data.limits || 10
        }&status=${data.status || ""}&request_date=${data.request_date || ""}`,
        authHeaders()
      );
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      console.log("error:", error.response);
      return error.response.data.message;
    }
  }
);

export const getSupportRequestById = createAsyncThunk(
  "getSupportRequestById",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/support-request/${data.id}`,
        authHeaders()
      );
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

export const updateSupportRequest = createAsyncThunk(
  "updateSupportRequest",
  async (data) => {
    try {
      const response = await Axios.put(
        `/support-request/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 201 && data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      console.log("error:", error.response);
    }
  }
);

export const addRequestReply = createAsyncThunk(
  "addRequestReply",
  async (data) => {
    try {
      const response = await Axios.post(
        `/support-request/reply`,
        data.data,
        authHeaders()
      );
      if (response.status === 201 && data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      console.log("error:", error.response);
    }
  }
);
export const updateRequestReply = createAsyncThunk(
  "updateRequestReply",
  async (data) => {
    try {
      const response = await Axios.put(
        `/support-request/reply/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 201 && data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      console.log("error:", error.response);
    }
  }
);

const initialState = {
  supportReqList: [],
  totalsupportReqCount: 0,
  supportReqDetail: {},
  loader: false,
  extraLoader: false,
  updateComponent: "",
  msgComponent: "",
  msgLoader: false,
};

const supportReqSlice = createSlice({
  name: "supportReq",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    //SUPPORT REQ LIST
    builder.addCase(getSupportRequests.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getSupportRequests.fulfilled, (state, action) => {
      state.loader = false;
      state.supportReqList = action.payload.rows;
      state.totalsupportReqCount = action.payload.count;
    });
    builder.addCase(getSupportRequests.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //SUPPORT REQ BY ID
    builder.addCase(getSupportRequestById.pending, (state) => {
      state.extraLoader = true;
    });
    builder.addCase(getSupportRequestById.fulfilled, (state, action) => {
      state.extraLoader = false;
      state.supportReqDetail = action.payload;
    });
    builder.addCase(getSupportRequestById.rejected, (state, action) => {
      state.extraLoader = false;
      state.error = action.payload;
    });

    //UPDATE SUPPORT REQ
    builder.addCase(updateSupportRequest.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateSupportRequest.fulfilled, (state, action) => {
      state.loader = false;
      state.updateComponent = action.payload;
    });
    builder.addCase(updateSupportRequest.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //ADD MSG
    builder.addCase(addRequestReply.pending, (state) => {
      state.msgLoader = true;
    });
    builder.addCase(addRequestReply.fulfilled, (state, action) => {
      state.msgLoader = false;
      state.msgComponent = action.payload;
    });
    builder.addCase(addRequestReply.rejected, (state, action) => {
      state.msgLoader = false;
      state.error = action.payload;
    });
    //UPDATE MSG
    builder.addCase(updateRequestReply.pending, (state) => {
      state.msgLoader = true;
    });
    builder.addCase(updateRequestReply.fulfilled, (state, action) => {
      state.msgLoader = false;
      state.msgComponent = action.payload;
    });
    builder.addCase(updateRequestReply.rejected, (state, action) => {
      state.msgLoader = false;
      state.error = action.payload;
    });
  },
});

export default supportReqSlice.reducer;
