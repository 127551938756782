import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";

import "../../css/LoginPage.css";
import { useForm } from "../../hooks/useForm";
import { validateEmail } from "../../component/Common/PageComponent/Login/LoginPageComponents";
import LoginPageForm from "../../component/Common/PageComponent/Login/LoginPageForm";
import loginImage from "../../images/loginBgImg.jpg";
import logoImage from "../../images/LogoImage/Logo.svg";
import { loginUser } from "../../store/slice/userSlice";
import Loader from "../../component/Common/CommonComponent/Loader";
import { logoSvg } from "../../icons";

// toast.configure();
const LoginPage = () => {
  const dispatch = useDispatch();

  const loginLoader = useSelector((state) => state.user.loader);

  const initialFValues = {
    email: "",
    password: "",
    inputType: "password",
  };

  const validate = (fieldValues = values) => {
    var temp = { ...errors };

    return validateEmail(temp, fieldValues, values, setErrors);
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const signInHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      const data = {
        email: values.email,
        password: values.password,
      };
      dispatch(loginUser(data));
    }
  };

  if (loginLoader) {
    return <Loader />;
  }

  return (
    <section className="login_section">
      <div className="login_left_image">
        <img src={loginImage} alt="DTV login photo" draggable="false" />
      </div>
      <div className="login_content">
        <div className="login_top_content">
          {/* <img
            src={logoImage}
            alt="DTV logo"
            className="login_top_logo"
            draggable="false"
          /> */}
          {logoSvg}
          <h3>Welcome Back !</h3>
          <p>Sign in to DTV Shredder</p>
        </div>
        <div className="login_input_logo_content">
          <form className="login_input_content">
            <LoginPageForm
              values={values}
              errors={errors}
              setValues={setValues}
              handleInputChange={handleInputChange}
              signInHandler={signInHandler}
            />
          </form>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;
