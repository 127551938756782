import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { toast } from "react-toastify";
import { routes } from "../../constants";

export const getDtvUsers = createAsyncThunk("getDtvUsers", async (data) => {
  try {
    const response = await Axios.get(
      `/user?start=${data.start || 0}&limits=${data.limits || 0}`,
      authHeaders()
    );
    return response.data.data;
    // return { data: response.data, count: response.headers["x-total-count"] };
  } catch (error) {
    toast.error(error.response.data.message, {
      autoClose: 3000,
    });
    console.log("error:", error.response);
    return error.response.data.message;
  }
});

export const updateDtvUser = createAsyncThunk("updateDtvUser", async (data) => {
  try {
    const response = await Axios.put(
      `/user/${data.id}`,
      data.data,
      authHeaders()
    );
    if (response.status === 201) {
      toast.success(data.toast, {
        autoClose: 3000,
      });
      data.navigate(`${routes.vehiclesPage}`);
      return response.data;
    }
  } catch (error) {}
});

const initialState = {
  dtvUserList: [],
  totalUserCount: 0,
  userDetail: {},
  loader: false,
  updateComponent: "",
};

const dtvUserSlice = createSlice({
  name: "dtvUsers",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    //DTV USER LIST
    builder.addCase(getDtvUsers.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getDtvUsers.fulfilled, (state, action) => {
      state.loader = false;
      state.dtvUserList = action.payload.rows;
      state.totalUserCount = action.payload.count;
    });
    builder.addCase(getDtvUsers.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //UPDATE DTV USER
    builder.addCase(updateDtvUser.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateDtvUser.fulfilled, (state, action) => {
      state.loader = false;
      state.updateComponent = action.payload;
    });
    builder.addCase(updateDtvUser.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default dtvUserSlice.reducer;
