import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getInvVehicleById } from "../../store/slice/inventorySlice";
import Loader from "../../component/Common/CommonComponent/Loader";
import AddVehicleInvPage from "./AddVehicleInvPage";

const UpdateVehicleInvPage = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const InvVehDetail = useSelector((state) => state.inventory.invVehicleDetail);
  const loading = useSelector((state) => state.inventory.loader);

  useEffect(() => {
    dispatch(getInvVehicleById({ id: params.id }));
  }, [params]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <AddVehicleInvPage editDetail={InvVehDetail} isEdit={true} />
      )}
    </>
  );
};

export default UpdateVehicleInvPage;
