import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CommonHeading from "../../component/Common/CommonComponent/CommonHeading";
import { routes } from "../../constants";
import Pagination from "../../component/Pagination/Pagination";
import Loader from "../../component/Common/CommonComponent/Loader";
import { deleteVersionModal, getVersion } from "../../store/slice/versionSlice";
import VersionsList from "../../component/Common/PageComponent/Version/VersionList";
import { toast } from "react-toastify";
import DeleteVersionModal from "../../component/Common/Modals/DeleteVersionModal";
import { Modal } from "react-bootstrap";

const VersionPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const ref = useRef(false);
  const { page_number } = params;

  const [pageNumber, setPageNumber] = useState(page_number - 1);
  const [showDeteteModal, setShowDeteteModal] = useState(false);
  const [deteteModalId, setDeteteModalId] = useState("");

  const VersionList = useSelector((state) => state.version?.versionList);
  const totalCount = useSelector((state) => state.version?.totalVersionCount);
  const isLoading = useSelector((state) => state.version?.loader);

  useEffect(() => {
    if (ref.current) {
      dispatch(getVersion({ start: (page_number - 1) * 10, limits: 10 }));
    }
  }, [pageNumber]);

  useEffect(() => {
    dispatch(getVersion({ start: (page_number - 1) * 10, limits: 10 }));
    ref.current = true;
  }, []);

  const onPageChange = (page_number) => {
    if (ref.current) {
      setPageNumber(page_number);
    }
  };

  const showDeleteModal = (id) => {
    setShowDeteteModal(true);
    setDeteteModalId(id);
  };

  const closeDeleteModal = () => {
    setShowDeteteModal(false);
    setDeteteModalId("");
  };

  const deleteVehHandler = () => {
    dispatch(
      deleteVersionModal({
        id: deteteModalId,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            toast.success("Version has been deleted!", {
              autoClose: 3000,
            });
            dispatch(getVersion({ start: (page_number - 1) * 10, limits: 10 }));
            setShowDeteteModal(false);
          }
        },
      })
    );
  };

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading="VERSION LIST" />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="creat_edit_project_btn_row_flex">
              <div>
                <Link
                  className="comman_btn ml-auto"
                  to={`${routes.addVersion}`}
                >
                  Add Version
                </Link>
              </div>
            </div>

            <div className="custom_data_table_content">
              <VersionsList
                list={VersionList}
                showDeleteModal={showDeleteModal}
              />
            </div>

            {totalCount > 0 && (
              <Pagination
                totalRecords={totalCount}
                onPageChange={onPageChange}
              />
            )}

            <Modal
              className="modal_main_component modal_background_content"
              // dialogClassName="custom-dialog"
              show={showDeteteModal}
              onHide={closeDeleteModal}
              centered
            >
              <DeleteVersionModal
                deteteModalId={deteteModalId}
                closeDeleteModal={closeDeleteModal}
                deleteVehHandler={deleteVehHandler}
              />
            </Modal>
          </>
        )}
      </div>
    </>
  );
};

export default VersionPage;
