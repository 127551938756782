import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CommonHeading from "../../component/Common/CommonComponent/CommonHeading";
import { backBtnArrow } from "../../icons";
import { useForm } from "../../hooks/useForm";
import Loader from "../../component/Common/CommonComponent/Loader";
import VersionForm from "../../component/Common/PageComponent/Version/VersionForm";
import { versionFormValidate } from "../../component/Common/PageComponent/Version/VersionCommon";
import { addVersion, getBatchList } from "../../store/slice/versionSlice";
import { fileUpload } from "../../helpers/fileUpload";
import { toast } from "react-toastify";

const AddVersionPage = ({ editDetail, isEdit }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const versionReducer = useSelector((state) => state?.version);
  const { loader, batchList } = versionReducer;

  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const typeOption =
    (batchList &&
      batchList?.map((item, index) => {
        return {
          id: item?.id,
          value: item?.batch_name,
          label: item?.batch_name,
        };
      })) ||
    [];
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (selected) => {
    setSelectedOptions(selected);
  };

  const initialFValues = {
    version: editDetail && editDetail.version ? editDetail.version : "",
    hardware_batch:
      editDetail && editDetail.hardware_batch ? editDetail.hardware_batch : "",
    series: editDetail && editDetail.series ? editDetail.series : "",
    description:
      editDetail && editDetail.description ? editDetail.description : "",
    bin_file: editDetail && editDetail.bin_file ? editDetail.bin_file : "",
    app_configuration:
      editDetail && editDetail.app_configuration
        ? editDetail.app_configuration
        : "",
    motor_configuration:
      editDetail && editDetail.motor_configuration
        ? editDetail.motor_configuration
        : "",
    force: false,
  };

  // validation
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    return versionFormValidate(fieldValues, temp, setValues, values, setErrors);
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const handleVersionSubmit = async () => {
    if (validate()) {
      try {
        // Create FormData instances for each file
        const binFileFormData = new FormData();
        const appConfigFormData = new FormData();
        const motorConfigFormData = new FormData();

        // Append the files to their respective FormData objects
        if (values.bin_file) {
          binFileFormData.append("file", values.bin_file);
        }
        if (values.app_configuration) {
          appConfigFormData.append("file", values.app_configuration);
        }
        if (values.motor_configuration) {
          motorConfigFormData.append("file", values.motor_configuration);
        }

        // Upload the files and get the URLs
        const binFileUrl = values.bin_file
          ? await dispatch(fileUpload(binFileFormData))
          : null;
        const appConfigUrl = values.app_configuration
          ? await dispatch(fileUpload(appConfigFormData))
          : null;
        const motorConfigUrl = values.motor_configuration
          ? await dispatch(fileUpload(motorConfigFormData))
          : null;

        // Construct the payload for the final API request
        const payload = {
          version: values.version,
          hardware: values.hardware_batch,
          series: values.series,
          description: values.description,
          type: JSON.stringify(selectedOptions.map((item) => item.id)),
          bin: binFileUrl?.payload || null,
          app_config: appConfigUrl?.payload || null,
          motor_config: motorConfigUrl?.payload || null,
          forced: isChecked,
        };

        // Dispatch the final API call to add the version
        dispatch(
          addVersion({
            data: payload,
            onSuccess: () => {
              navigate(`/dtv-version/page/1`);
              toast.success("Version added successfully!", {
                autoClose: 3000,
              });
            },
            onFailure: (error) => {
              console.error("Failed to add version:", error);
            },
          })
        );
      } catch (error) {
        console.error("File upload failed:", error);
      }
    }
  };

  useEffect(() => {
    dispatch(getBatchList());
  }, []);

  return (
    <div className="project_edit_main_content">
      <CommonHeading heading={"ADD VERSION"} />
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="project_edit_main_link">
            <div className="comman_center_btn_container">
              <button className="go_back_btn" onClick={() => navigate(-1)}>
                {backBtnArrow} Back
              </button>
            </div>
          </div>
          <VersionForm
            values={values}
            handleInputChange={handleInputChange}
            errors={errors}
            handleVersionSubmit={handleVersionSubmit}
            isEdit={isEdit}
            selectedOptions={selectedOptions}
            handleChange={handleChange}
            typeOption={typeOption}
            isChecked={isChecked}
            handleCheckboxChange={handleCheckboxChange}
          />
        </>
      )}
    </div>
  );
};

export default AddVersionPage;
