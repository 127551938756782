import React from "react";
import { tableHeadItemsForTrip } from "../../CommonComponent/TableHeads";
import { Link } from "react-router-dom";
import { eyeIcon } from "../../../../icons";
import moment from "moment/moment";
import { defaultTimeZone } from "../../../../helpers/commonFunction";

const TripTableList = ({ list, showModal }) => {
  const getRiddenDuration = (duration) => {
    const timeString = duration;
    const timeParts = timeString.split(":");
    const hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);
    const seconds = parseInt(timeParts[2]);

    let formattedTime = "";
    if (hours > 0) {
      formattedTime += hours + " Hr ";
    }
    formattedTime += minutes + " Min " + seconds + " sec";

    return formattedTime;
  };

  // const fetchAddress = async (latLongObj) => {
  //   const address = await getLocation(latLongObj);
  //   console.log("address123456798765", address);
  //   return address;
  // };

  return (
    <>
      {list?.length === 0 || !list ? (
        <p className="no_data_found">No data Found</p>
      ) : (
        <table className="custom_data_table">
          <thead className="custom_data_table_head">
            <tr>
              {tableHeadItemsForTrip.map((item, index) => {
                return (
                  <th
                    className="custom_data_table_heading text-center"
                    key={item.id}
                  >
                    {item.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="custom_data_table_body">
            {list?.map((trip, i) => {
              return (
                <tr className="custom_data_table_row" key={trip.id}>
                  <td className="custom_data_table_item table_item">
                    {moment
                      .utc(trip.created_at, "YYYYMMDD HH:mm:ss")
                      .clone()
                      .tz(defaultTimeZone)
                      .format("DD/MM/YYYY")}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {trip.shredder_user_mapping?.user?.name}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {trip.time_ridden
                      ? getRiddenDuration(trip.time_ridden)
                      : "-"}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {trip.miles_ridden ? trip.miles_ridden + " Miles" : "-"}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {trip.address?.start}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {trip.address?.end}
                  </td>
                  <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                    <div className="custom_data_table_view_edit_btn_item_row">
                      <button
                        className="custom_data_table_view_edit_item_btn"
                        aria-label="view"
                        //   state={name === "salon" && { salon: salon }}
                        // onClick={() => showModal(trip.id)}
                      >
                        {eyeIcon}
                      </button>
                      {/* <Link
                      aria-label="edit"
                      className="custom_data_table_view_edit_item_btn"
                    >
                      {editIcon}
                    </Link> */}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
};

export default TripTableList;
